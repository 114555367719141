import React from "react";

import Vehicles from "../../components/VT/Vehicles";

const ArchivedVehicles = () => {

  return (
    <Vehicles
      apiEnd={'ArchivedVehicles/'}
      vehicleActive={false} // Prevents the add button from showing if false
    />
  )
}

export default ArchivedVehicles;
