import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import API from "../../services/API";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
// Core MUI components import
import {
  CircularProgress,
  Tooltip,
  Backdrop,
  Box,
  Chip,
  Fab,
  Menu,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import MUIDataTable, { TableFilterList } from "mui-datatables";
import CustomToolbarSelect from "../CustomToolBarSelect";
import { MoreVert, Add } from "@material-ui/icons";
// import VehicleHistoryModal from "./Modals/VehicleHistoryModal";
import ConfirmationPopup from "./ConfirmationPopup";
import { utils, write } from "xlsx";
import { saveAs } from "file-saver";

// Import
// import FilterChip from "./vantonderimports";

// Modals
import VehicleHistoryModal from "./Modals/VehicleHistoryModal";
import VehicleFormModal from "./Modals/VehicleFormModal";
import ArchiveConfirmFormModal from "./Modals/ArchiveConfirmFormModal";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

const stripedTableStyle = (theme) => ({
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
});

const Vehicles = ({
  apiEnd,
  vehicleActive,
  vehicleTableState,
  serviceRecordTableState,
  dispatch,
}) => {
  const history = useHistory();
  const [currentDataSet, setCurrentDataSet] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [anchorIndex, setAnchorIndex] = useState(null);
  const open = Boolean(anchorEl);
  const [modelOpen, setModelOpen] = useState(false);
  const [vehicleClicked, setVehicleClicked] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [modelSumOpen, setSumModelOpen] = useState(false);
  const [newRecord, setNewRecord] = useState(false);

  // For confirmation
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [selectedRows, setSelectedRows] = useState(null);

  // For vehicle archival
  const [openArchiveModal, setOpenArchiveModal] = useState(false);
  const [vehicleSelected, setVehicleSelected] = useState(false);

  // For deletion
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [deleteTitle, setDeleteTitle] = useState(null);

  const FilterChip = ({ label, data, onDelete }) => {
    return (
      <Box pb={1} pt={1}>
        <Chip
          variant="outlined"
          // color="secondary"
          label={label}
          onDelete={onDelete}
        />
      </Box>
    );
  };

  const CustomFilterList = (props) => {
    return <TableFilterList {...props} ItemComponent={FilterChip} />;
  };

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTableHead: {
          root: { backgroundColor: "#666" },
        },
        MUIDataTable: {
          paper: {
            classNames: "card1",
          },
        },
        MUIDataTableHeadCell: {
          fixedHeader: { backgroundColor: "#666", color: "#FFF" },
          toolButton: {
            backgroundColor: "#666",
            color: "#FFF",
            fillColor: "#fff",
          },
          sortActive: { backgroundColor: "#666", color: "#FFF" },
          sortLabelRoot: { color: "#FFF" },
        },
        // MuiTableSortLabel: {
        //   active: { fillColor: "#fff" },
        // },
        MUIDataTableSelectCell: {
          headerCell: {
            backgroundColor: "#666",
            color: "#fff",
          },
          checkBoxRoot: {
            color: "#fff",
          },
        },
        MUIDataTableBodyCell: {
          root: {
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      },
    });

  // On first load, clear filters
  useEffect(() => {
    dispatch({ type: "CLEAR_VEHICLE_TABLE_STATE" });
  }, []);

  useEffect(() => {
    async function getVec() {
      setIsLoading(true);
      const url = process.env.REACT_APP_API_URL + "/" + apiEnd;
      const SRData = await API.getAPIData(url, "GET", null);
      if (SRData.status === 200) {
        let responseObjectTemp = await SRData.json();
        // console.log(responseObjectTemp);
        setCurrentDataSet(responseObjectTemp);
      }
      setIsLoading(false);
    }

    getVec().then(() => {}); //.then((value) => console.log(value));
    // setSelectedRows([]);

    checkFiltered();
  }, [refreshPage]);

  useEffect(() => {
    // TODO: Give more info on the selected vehicles
    setDeleteTitle("Delete Vehicle");
    setDeleteMessage("Are you sure you want to delete the selected vehicle?");
    if (vehicleActive) {
      setTitle("Archive Vehicle");
      setMessage(
        "Are you sure you want to archive the selected vehicle/s and ALL of their records?"
      );
    } else {
      setTitle("Activate Vehicle");
      setMessage(
        "Are you sure you want to activate the selected vehicle/s and ALL of their records?"
      );
    }
  }, []);

  // function viewVehicle() {
  //   history.push("/vehicleForm", { data: currentDataSet[anchorIndex] });
  // }

  useEffect(() => {
    // console.log(anchorIndex);
    if (currentDataSet) {
      // console.log(currentDataSet[anchorIndex].vehicle_ID);
      viewVehicleRecords(currentDataSet[anchorIndex].vehicle_ID);
    }
  }, [anchorIndex]);

  function viewVehicleRecords(vehicleID) {
    if (vehicleActive) {
      // console.log(vehicleTableState);
      dispatch({
        type: "SERVICE_RECORD_TABLE_STATE",
        value: {
          ...serviceRecordTableState,
          vehicleFilterIds: [vehicleID],
          recordTypeFilter: [],
          page: 0,
          rowsPerPage: vehicleTableState.rowsPerPage,
        },
      });
      history.push("/selectedVehicle");
    } else {
      // console.log(vehicleTableState);
      dispatch({
        type: "SERVICE_RECORD_TABLE_STATE",
        value: {
          ...serviceRecordTableState,
          vehicleFilterIds: [vehicleID],
          recordTypeFilter: [],
          page: 0,
          rowsPerPage: vehicleTableState.rowsPerPage,
        },
      });
      history.push("/service/archived");
    }
  }

  async function toggleVehicleStatus(index) {
    // console.log("Archiving vehicle: " + currentDataSet[index].vehicle_ID);
    // setIsLoading(true);

    var messageAlertType = "success";
    var messageValue = "";
    const body = {
      vehicle_ID: currentDataSet[index].vehicle_ID,
      date_inservice: null,
      date_outservice: null,
      // TODO: Add date_inservice and date_outservice in the implementation
    };
    const url =
      process.env.REACT_APP_API_URL +
      "/ArchivedVehicles/" +
      (vehicleActive ? "archive/" : "unarchive/");
    const SRData = await API.getAPIData(url, "POST", body);
    // console.log(SRData);
    if (SRData.status === 200) {
      await SRData.json();
      messageValue =
        "Successfully " +
        (vehicleActive ? "archived" : "activated") +
        " vehicle with ID: " +
        currentDataSet[index].vehicle_ID;
      messageAlertType = "success";
    } else if (SRData.status > 200) {
      // TODO: Implement error handling here to give
      messageValue =
        "An error occurred, can not activate vehicle with ID: " +
        currentDataSet[index].vehicle_ID;
      messageAlertType = "error";
      await SRData.json();
    }
    dispatch({
      type: "NOTIFICATION",
      value: true,
      message: messageValue,
      messageType: messageAlertType,
    });
    setIsLoading(false);

    // Force page refresh
    setRefreshPage(!refreshPage);
  }

  async function deleteRecord(record) {
    await API.getAPIData(record.url, "DELETE", record);
  }

  async function deleteVehicle(index) {
    // console.log("Archiving vehicle: " + currentDataSet[index].vehicle_ID);
    setIsLoading(true);
    const body = {
      vehicle_ID: currentDataSet[index].vehicle_ID,
      // date_inservice: currentDataSet[index].date_inservice,
      // date_outservice: currentDataSet[index].date_outservice,
      // TODO: Add date_inservice and date_outservice in the implementation
    };
    const url =
      process.env.REACT_APP_API_URL +
      "/ArchivedVehicles/" +
      (vehicleActive ? "archive/" : "unarchive/");
    const SRData = await API.getAPIData(url, "POST", body);
    if (SRData.status === 200) {
      await SRData.json();
    }
    setIsLoading(false);

    // Force page refresh
    setRefreshPage(!refreshPage);
  }

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    // setAnchorIndex(index);
  };

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const iconButtonElement = (index) => {
    return (
      <div>
        <IconButton
          aria-label="More"
          aria-owns={open ? "long-menu" : null}
          aria-haspopup="true"
          onClick={(event) => handleClick(event, index)}
        >
          <MoreVert color={"action"} />
        </IconButton>
        {/*{rightIconMenu(index)}*/}
      </div>
    );
  };

  let emptyVehicleRecord = {
    active_inservice: true,
    chassis_no: "",
    date_added: new Date().toISOString(),
    date_inservice: new Date().toISOString().substring(0, 10),
    date_outservice: new Date().toISOString().substring(0, 10),
    engine_no: "",
    model: "",
    service_interval: 0,
    url: process.env.REACT_APP_API_URL + "/Vehicles/",
    vehicle_ID: "",
    year_model: 2021,
  };

  let emptyArchiveRecord = {
    vehicle_ID: "",
    chassis_no: "",
    date_end: new Date().toISOString().substring(0, 10),
    date_start: new Date().toISOString().substring(0, 10),
  };

  function addNew() {
    setNewRecord(true);
    setModelOpen(true);
    setAnchorEl(null);
  }

  const columns = [
    {
      name: "vehicle_ID",
      label: "Vehicle ID",
      options: {
        display: !isFiltered,
        filter: true,
        filterType: "multiselect",
        filterList: vehicleTableState.vehicleFilterIds
          ? vehicleTableState.vehicleFilterIds
          : null,
        sort: true,
        filterOptions: {
          fullWidth: true,
        },
      },
    },
    // {
    //   name: "active_inservice",
    //   label: "Active In-service",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRender: value => value ? "True" : "False"
    //   }
    // },
    {
      name: "date_inservice",
      label: "Date In-service",
      options: {
        filter: false,
        sort: true,
      },
    },
    // {
    //   name: "date_added",
    //   label: "Date Added",
    //   options: {
    //     filter: true,
    //     sort: true,
    //   }
    // },
    // {
    //   name: "date_outservice",
    //   label: "Date Out-service",
    //   options: {
    //     filter: false,
    //     sort: false,
    //   },
    // },
    {
      name: "year_model",
      label: "Year",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "model",
      label: "Model",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "service_interval",
      label: "Service Interval",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "chassis_no",
      label: "Chassis Number",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "engine_no",
      label: "Engine Number",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "action",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => iconButtonElement(dataIndex),
        viewColumns: false,
      },
    },
  ];

  // useEffect(() => {
  //   // console.log( "this in useEffect",vehicleClicked);
  //   if (vehicleClicked !== null) {
  //     setModelOpen(!modelOpen);
  //   }
  // }, [vehicleClicked]);

  function rowClicked(dataIndex, colIndex) {
    // Check if the action column was selected
    setVehicleClicked(currentDataSet[dataIndex]);
    // console.log("this ", currentDataSet[dataIndex]);
    // console.log("this 2 ", currentDataSet[dataIndex]);
    if (columns[colIndex].name !== "action") {
      // viewVehicleRecords();
      setAnchorIndex(dataIndex);
    }
  }

  function checkFiltered() {
    // console.log("I RAN NOW");vehicleTableState.vehicleFilterIds
    // console.log(serviceRecordTableState);filteredVehicles

    if (!vehicleTableState.vehicleFilterIds.length) {
      // console.log("I am empty");
      setIsFiltered(false);
    } else {
      // console.log("I am not empty");
      setIsFiltered(true);
    }
  }

  useEffect(() => {
    // console.log(vehicleTableState);
    checkFiltered();
  }, [vehicleTableState]);

  async function deleteSelectedRows() {
    await selectedRows.data.forEach(
      (value) => deleteRecord(currentDataSet[value.dataIndex])
      // console.log(currentDataSet[selectedRows.data[0].dataIndex])
    );
    dispatch({
      type: "NOTIFICATION",
      value: true,
      message: "The record(s) has been deleted",
      messageType: "warning",
    });
    setDeleteModal(false);

    setRefreshPage(!refreshPage);

    // // Test if one is selected, present date range modal other do bulk archive by popup
    // if (selectedRows.data.length > 1 || !vehicleActive) {
    //   setConfirmationModal(false);
    // } else {
    //   setOpenArchiveModal(false);
    // }
    // setOpenArchiveModal(false);
  }

  async function archiveSelectedRows() {
    await selectedRows.data.forEach((value) =>
      toggleVehicleStatus(value.dataIndex)
    );

    // TODO: Add message to the notification dispatch
    dispatch({ type: "NOTIFICATION", value: true });
    // setConfirmationModal(false);

    // Test if one is selected, present date range modal other do bulk archive by popup
    if (selectedRows.data.length > 1 || !vehicleActive) {
      setConfirmationModal(false);
    } else {
      setOpenArchiveModal(false);
    }
    // setOpenArchiveModal(false);
  }

  function onRowsArchive(selectedRows) {
    setSelectedRows(selectedRows);
    console.log(selectedRows);

    if (selectedRows.data.length > 1) {
      setConfirmationModal(true);
    } else if (!vehicleActive) {
      setConfirmationModal(true);
      setVehicleSelected(
        currentDataSet[selectedRows.data[0].dataIndex].vehicle_ID
      );
    } else {
      setOpenArchiveModal(true);
      // Get single vehicle ID for modal if only one row selected
      setVehicleSelected(
        currentDataSet[selectedRows.data[0].dataIndex].vehicle_ID
      );
    }
  }

  function onRowsDelete(selectedRows) {
    setSelectedRows(selectedRows);
    setDeleteModal(true);
  }

  const options = {
    textLabels: {
      selectedRows: {
        delete: title,
      },
      toolbar: {
        downloadCsv: "Download XLSX",
      },
    },
    fixedHeader: true,
    fixedSelectColumn: false,
    filterType: "checkbox",
    responsive: "standard",
    tableBodyHeight: "82vh",
    pagination: false,
    // filterType: "checkbox",
    // responsive: "simple",
    // onRowClick: (rowData, rowMeta) => rowClicked(rowMeta.dataIndex),
    // onCellClick: (colData, cellMeta) => rowClicked(cellMeta.dataIndex),
    onCellClick: (colData, cellMeta) =>
      rowClicked(cellMeta.dataIndex, cellMeta.colIndex),
    onRowsDelete: (rowsDeleted) => {
      setSelectedRows(rowsDeleted);
      // onselect()
      // setOpenArchiveModal(true);
      // setConfirmationModal(true);
    },
    rowsPerPageOptions: [5, 10, 25, 50, 100, 200],
    rowsPerPage: vehicleTableState.rowsPerPage,
    // count: recordCount,
    // serverSide: true,
    page: vehicleTableState.page,
    print: false,

    onTableChange: async (action, tableState) => {
      // console.log(action);
      // console.log(tableState);
      if (
        action === "filterChange" ||
        action === "changePage" ||
        action === "changeRowsPerPage" ||
        action === "resetFilters"
      ) {
        const filteredVehicles = tableState.filterList[0];
        const page = tableState.page;
        const rowsPerPage = tableState.rowsPerPage;
        // console.log(filteredVehicles);
        dispatch({
          type: "VEHICLE_TABLE_STATE",
          value: {
            vehicleFilterIds: filteredVehicles,
            page: page,
            rowsPerPage: rowsPerPage,
          },
        });
      }
    },
    customToolbar: vehicleActive
      ? () => {
          // return <CustomToolbar addNew={addNew} />;
          return (
            <Tooltip title="Add New">
              <Fab
                color="inherit"
                size="small"
                aria-label="add"
                variant="extended"
                // style={
                //   {
                //     //   position: "fixed",
                //     //   bottom: "2rem",
                //     //   right: "2rem",
                //   }
                // }
                onClick={addNew}
              >
                <Add />
              </Fab>
            </Tooltip>
          );
        }
      : null,
    customToolbarSelect: (selectedRows) => {
      // Delete rows has been adapted to archive the vehicles
      return (
        <CustomToolbarSelect
          toggleVehicleStatus={() => onRowsArchive(selectedRows)}
          deleteVehicle={() => onRowsDelete(selectedRows)}
          selectedRows={selectedRows}
          isArchiveIcon={vehicleActive}
          title={title}
        />
      );
    },
    onDownload: (buildHead, buildBody, columns, values) => {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      // build the json, this is one way to do it
      // console.log(values.forEach((val) => // console.log(val)));

      const json = values.reduce((result, val) => {
        const temp = {};
        val.data.forEach((v, idx) => {
          temp[columns[idx].name] = v;
        });
        result.push(temp);
        return result;
      }, []);

      const fileName = `Vehicles`;
      const ws = utils.json_to_sheet(json);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      saveAs(data, fileName + fileExtension);
      // cancel default  CSV download from table
      return false;
    },
  };

  return (
    <div>
      <VehicleHistoryModal
        open={modelSumOpen}
        setOpen={setSumModelOpen}
        vehicleClicked={vehicleClicked ? vehicleClicked.vehicle_ID : null}
      />
      <VehicleFormModal
        open={modelOpen}
        setOpen={setModelOpen}
        vehicleClicked={newRecord ? emptyVehicleRecord : vehicleClicked}
        viewServiceRecordsSend={viewVehicleRecords}
        setVehicleClickedSend={() => setVehicleClicked(null)}
        setRefreshPageSend={() => setRefreshPage(!refreshPage)}
        newRecord={newRecord}
        setNewRecordSend={() => setNewRecord(false)}
      />
      <ArchiveConfirmFormModal
        open={openArchiveModal}
        setOpen={setOpenArchiveModal}
        newRecord={true}
        vehicleClicked={vehicleSelected}
        setRefreshPageSend={() => setRefreshPage(!refreshPage)}
      />
      {/* TODO: Implement the ConfirmationPopup/Modal for Archiving and Deleting - VTT-51 */}
      <ConfirmationPopup
        open={confirmationModal}
        handleYes={() => archiveSelectedRows()}
        handleNo={() => setConfirmationModal(false)}
        title={title}
        message={message}
      />
      <ConfirmationPopup
        open={deleteModal}
        handleYes={() => deleteSelectedRows()}
        handleNo={() => setDeleteModal(false)}
        title={deleteTitle}
        message={deleteMessage}
      />
      <Menu elevation={2} anchorEl={anchorEl} open={open} onClose={onMenuClose}>
        <MenuItem
          onClick={() => {
            setSumModelOpen(true);
            setAnchorEl(null);
          }}
        >
          View Technical Summary
        </MenuItem>
        <MenuItem
          onClick={() => {
            setModelOpen(true);
            setAnchorEl(null);
          }}
        >
          Edit Vehicle Details
        </MenuItem>
      </Menu>
      <Backdrop
        style={{ color: "#fff", zIndex: 2000 }}
        open={isLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={vehicleActive ? "Active Vehicles" : "Archived Vehicles"}
          data={currentDataSet ? currentDataSet : []}
          columns={columns}
          options={options}
          components={{
            TableFilterList: CustomFilterList,
          }}
        />
      </MuiThemeProvider>
      {/*<Fab color="secondary" aria-label="add"*/}
      {/*     style={{*/}
      {/*       position: "fixed",*/}
      {/*       bottom: "2rem",*/}
      {/*       right: "2rem"*/}
      {/*     }}*/}
      {/*     onClick={addNew}*/}
      {/*>*/}
      {/*  <AddIcon/>*/}
      {/*</Fab>*/}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isSignedIn: state.isSignedIn,
  accessToken: state.accessToken,
  refreshToken: state.refreshToken,
  vehicleTableState: state.vehicleTableState,
  serviceRecordTableState: state.serviceRecordTableState,
});

export default connect(mapStateToProps)(
  withStyles(stripedTableStyle, {
    withTheme: true,
  })(Vehicles)
);
