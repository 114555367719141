import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import PageBase from "../../PageBase";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { grey } from "@material-ui/core/colors";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import API from "../../../services/API";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { Close } from "@material-ui/icons";
import { Paper } from "@material-ui/core";
import globalStyles from "../../../styles";

const stripedTableStyle = (theme) => ({
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
});

// function Autocomplete(props) {
//   return null;
// }
//
// Autocomplete.propTypes = {
//   renderInput: PropTypes.func,
//   style: PropTypes.shape({width: PropTypes.number}),
//   id: PropTypes.string,
//   getOptionLabel: PropTypes.func,
//   options: PropTypes.any
// };
const ServiceRecordForm = ({ dispatch }) => {
  const history = useHistory();
  const styles = {
    toggleDiv: {
      marginTop: 20,
      marginBottom: 5,
    },
    toggleLabel: {
      color: grey[400],
      fontWeight: 100,
    },
    buttons: {
      marginTop: 30,
      float: "right",
      width: "100%",
    },
    saveButton: {
      marginLeft: 5,
    },
    deleteButton: {
      marginLeft: 5,
      backgroundColor: "#d22929",
    },
  };

  const [serviceRecord, setServiceRecord] = useState(null);
  const [availableVehicleList, setAvailableVehicleList] = useState(null);
  const [hotkeys, setHotkeys] = useState(null);
  const [serviceTypeList, setServiceTypeList] = useState(null); // List of available service types
  const hotKeysObject = {
    hotkey: "General",
    description: "",
    remarks: "",
  };
  const [jobEntryList, setJobEntryList] = useState([hotKeysObject]);

  useEffect(() => {
    fetchHotkeys().then((value) => console.log(value));
    getServiceTypeList().then((value) => console.log(value));

    console.log("STATE", history.location.state);
    if (history.location.state) {
      setServiceRecord(history.location.state.data);
      // If it's empty, use empty object
      if (history.location.state.data.job_data.length === 0) {
        setJobEntryList([hotKeysObject]);
      } else {
        setJobEntryList(history.location.state.data.job_data);
      }
    }

    // If it's a new record, fetch all the available vehicle IDs to populate the drop down
    if (history.location.state.newRecord) {
      getAvailableVehiclesList().then((value) => console.log(value));
    } else {
      setAvailableVehicleList([history.location.state.data.vehicle_ID]);
    }
  }, []);

  async function fetchHotkeys() {
    const tempUrl = process.env.REACT_APP_API_URL + "/Hotkeys/";
    console.log("Fetch URL", tempUrl);
    const SRData = await API.getAPIData(tempUrl, "GET", null);
    let responseObjectTemp = await SRData.json();
    responseObjectTemp = responseObjectTemp.map((value) => ({
      hotkey: value.tag_description
        ? value.tag_description + " (" + value.hotkey_tag + ")"
        : "General",
      description: value.main_description,
      remarks: value.tag_description,
    }));
    console.log(responseObjectTemp.unshift(hotKeysObject));
    setHotkeys(responseObjectTemp);
  }

  function getVehicle(vehicleID) {
    const tempUrl = process.env.REACT_APP_API_URL + "/Vehicles/" + vehicleID;
    console.log("Fetch URL", tempUrl);
    const SRData = API.getAPIData(tempUrl, "GET", null);
    SRData.then((value) => {
      value.json().then((value1) => {
        console.log(value1);
        setServiceRecord({
          ...serviceRecord,
          chassis_no: value1.chassis_no,
          vehicle_ID: vehicleID,
        });
      });
    }).catch((reason) => {
      console.log(reason);
    });
  }

  async function getServiceTypeList() {
    const url = process.env.REACT_APP_API_URL + "/ListServiceRecordsTypes/";
    const SRData = await API.getAPIData(url, "GET", null);
    if (SRData.status === 200) {
      let responseObjectTemp = await SRData.json();
      console.log("setServiceTypeList", responseObjectTemp.unshift(""));
      setServiceTypeList(responseObjectTemp);
    }
  }

  async function getAvailableVehiclesList() {
    const url = process.env.REACT_APP_API_URL + "/ListVehicleNumbers/active";
    const SRData = await API.getAPIData(url, "GET", null);
    if (SRData.status === 200) {
      let responseObjectTemp = await SRData.json();
      console.log(responseObjectTemp.unshift(""));
      setAvailableVehicleList(responseObjectTemp);
    }
  }

  async function saveBtn() {
    serviceRecord.job_data = jobEntryList;
    serviceRecord.job_data.pop(); // Remove last empty record
    const response = await API.getAPIData(
      serviceRecord.url,
      history.location.state.newRecord ? "POST" : "PUT",
      serviceRecord
    );
    console.log(response);
    if (response.status === 201 || response.status === 200) {
      dispatch({ type: "CONFIRMATION", value: true });
      history.push("/service/active");
      // history.goBack();
    }
  }

  async function deleteRecord() {
    const response = await API.getAPIData(
      serviceRecord.url,
      "DELETE",
      serviceRecord
    );
    console.log(response);
    // Delete was successful
    if (response.status === 204) {
      dispatch({ type: "CONFIRMATION", value: true });
      history.goBack();
    }
  }

  function goBack() {
    history.goBack();
  }

  function removeJobItem(index) {
    console.log(index);
    let tempJobList = [...jobEntryList]; // Make copy
    if (tempJobList && tempJobList.length > 1) {
      // console.log(tempJobList.splice(index,index));
      tempJobList.splice(index, 1);
      console.log("tempJobList", tempJobList);
      setJobEntryList(tempJobList);
    } else {
      setJobEntryList([hotKeysObject]); // Empty object
    }
  }

  // // Use effect to add empty object
  useEffect(() => {
    console.log(jobEntryList);
    // Get last element in job list array
    const lastItem = jobEntryList[jobEntryList.length - 1];

    // If there's something inside, add an empty object at the end
    if (
      lastItem.hotkey !== "General" ||
      lastItem.remarks ||
      lastItem.description
    ) {
      let tempJobList = [...jobEntryList]; // Make copy
      tempJobList.push(hotKeysObject);
      setJobEntryList(tempJobList);
    }
  }, [jobEntryList]);

  function jobEntry(index) {
    // const [test, setTest] = useState('');
    return (
      hotkeys && (
        <div
          key={index}
          style={{ flexDirection: "row", display: "flex", marginTop: 20 }}
        >
          <Paper
            elevation={3}
            style={{
              paddingLeft: 20,
              paddingBottom: 20,
              paddingRight: 20,
              flex: 1,
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <Autocomplete
                  id="combo-box-demo"
                  options={hotkeys}
                  getOptionLabel={(option) => option.hotkey}
                  getOptionSelected={(option, value) => {
                    return option.hotkey === value.hotkey;
                  }}
                  fullWidth={true}
                  value={jobEntryList[index]}
                  onChange={(event, newValue) => {
                    console.log(newValue);
                    let tempJobList = [...jobEntryList]; // Make copy
                    // If newValue is not null, gooi newValue. Otherwise, gooi empty object
                    tempJobList[index] = newValue ? newValue : hotKeysObject;
                    setJobEntryList(tempJobList);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      label="Hotkey"
                      fullWidth={true}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <TextField
                  // hintText="Service Job Number"
                  label="Job Description"
                  fullWidth={true}
                  margin="normal"
                  value={jobEntryList[index].description}
                  onChange={(event) => {
                    console.log(jobEntryList);
                    let tempJobList = [...jobEntryList]; // Make copy
                    tempJobList[index].description = event.target.value;
                    setJobEntryList(tempJobList);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <TextField
                  // hintText="Service Job Number"
                  label="Remarks"
                  fullWidth={true}
                  margin="normal"
                  value={jobEntryList[index].remarks}
                  onChange={(event) => {
                    let tempJobList = [...jobEntryList]; // Make copy
                    tempJobList[index].remarks = event.target.value;
                    setJobEntryList(tempJobList);
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
          <div style={{ alignItems: "center", display: "flex" }}>
            <IconButton
              aria-label="delete"
              onClick={() => removeJobItem(index)}
            >
              <Close />
            </IconButton>
          </div>
        </div>
      )
    );
  }

  function brakesComponents() {
    return (
      <>
        {jobEntryList[0] && (
          <>
            <TextField
              label="Description"
              fullWidth={true}
              margin="normal"
              value={jobEntryList[0].description}
              onChange={(event) => {
                let tempJobList = [...jobEntryList]; // Make copy
                tempJobList[0].description = event.target.value;
                tempJobList[0].hotkey = "Brake";
                setJobEntryList(tempJobList);
              }}
            />

            <TextField
              label="Remarks"
              fullWidth={true}
              margin="normal"
              value={jobEntryList[0].remarks}
              onChange={(event) => {
                let tempJobList = [...jobEntryList]; // Make copy
                tempJobList[0].remarks = event.target.value;
                tempJobList[0].hotkey = "Brake";
                setJobEntryList(tempJobList);
              }}
            />
          </>
        )}
      </>
    );
  }

  function tyresComponents() {
    return (
      <div>
        <TextField
          label="Tyre Size"
          fullWidth={true}
          margin="normal"
          value={serviceRecord.tyre_size}
          onChange={(event) =>
            setServiceRecord({
              ...serviceRecord,
              tyre_size: event.target.value,
            })
          }
        />

        <TextField
          label="Tyre Type"
          fullWidth={true}
          margin="normal"
          value={serviceRecord.tyre_type}
          onChange={(event) =>
            setServiceRecord({
              ...serviceRecord,
              tyre_type: event.target.value,
            })
          }
        />

        <TextField
          label="Tyre Pos"
          fullWidth={true}
          margin="normal"
          value={serviceRecord.tyre_pos}
          onChange={(event) =>
            setServiceRecord({ ...serviceRecord, tyre_pos: event.target.value })
          }
        />

        {jobEntryList[0] && (
          <TextField
            // hintText="Service Job Number"
            label="Remarks"
            fullWidth={true}
            margin="normal"
            value={jobEntryList[0].remarks}
            onChange={(event) => {
              let tempJobList = [...jobEntryList]; // Make copy
              tempJobList[0].remarks = event.target.value;
              tempJobList[0].description = "Tyre";
              tempJobList[0].hotkey = "Tyre";
              setJobEntryList(tempJobList);
            }}
          />
        )}
      </div>
    );
  }

  function serviceComponent() {
    return (
      <div>
        <TextField
          label="Service Type"
          fullWidth={true}
          margin="normal"
          value={serviceRecord.service_type}
          onChange={(event) =>
            setServiceRecord({
              ...serviceRecord,
              service_type: event.target.value,
            })
          }
        />
        <TextField
          // hintText="Service Job Number"
          label="Service Job Number"
          fullWidth={true}
          margin="normal"
          value={serviceRecord.service_jobno}
          onChange={(event) =>
            setServiceRecord({
              ...serviceRecord,
              service_jobno: event.target.value,
            })
          }
        />
        <TextField
          // hintText="Service Brakes"
          label="Brakes (%)"
          fullWidth={true}
          margin="normal"
          type={"number"}
          value={serviceRecord.service_brakes}
          onChange={(event) =>
            setServiceRecord({
              ...serviceRecord,
              service_brakes: parseInt(event.target.value),
            })
          }
        />
        <div style={{ marginTop: 30 }}>
          <h3 style={globalStyles.title}>Jobs</h3>
        </div>
        {jobEntryList.map((value, index) => {
          console.log("jobEntryList", index);
          return jobEntry(index);
        })}
      </div>
    );
  }

  function maintenanceComponent() {
    return (
      <div>
        <div style={{ marginTop: 30 }}>
          <h3 style={globalStyles.title}>Jobs</h3>
        </div>
        {jobEntryList.map((value, index) => {
          console.log("jobEntryList", index);
          return jobEntry(index);
        })}
      </div>
    );
  }

  return (
    <PageBase
      title="Service Record"
      navigation="Application / Form Page"
      goBack={goBack}
      deleteRecord={deleteRecord}
      deleteTooltip={"Delete"}
      newRecord={history.location.state.newRecord}
    >
      {serviceRecord && (
        <form>
          {availableVehicleList && (
            <Autocomplete
              id="vehicle-id"
              options={availableVehicleList}
              fullWidth={true}
              value={serviceRecord.vehicle_ID}
              disabled={!history.location.state.newRecord}
              onChange={(event, newValue) => {
                console.log(newValue);
                if (newValue) {
                  setServiceRecord({ ...serviceRecord, vehicle_ID: newValue });
                  getVehicle(newValue);
                } else {
                  // Vehicle ID was cleared, also clear chassis ID
                  setServiceRecord({
                    ...serviceRecord,
                    chassis_no: "",
                    vehicle_ID: "",
                  });
                  // setServiceRecord({...serviceRecord, vehicle_ID: ""})
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  label="Vehicle ID"
                  fullWidth={true}
                />
              )}
            />
          )}

          <TextField
            // hintText="Chassis Number"
            label="Chassis Number"
            fullWidth={true}
            margin="normal"
            value={serviceRecord.chassis_no}
            disabled={true}
            onChange={(event) =>
              setServiceRecord({
                ...serviceRecord,
                chassis_no: event.target.value,
              })
            }
          />

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-completed"
              label="Date Completed"
              format="d MMM, yyyy"
              fullWidth={true}
              showTodayButton
              value={serviceRecord.date_completed}
              onChange={(value) =>
                setServiceRecord({
                  ...serviceRecord,
                  date_completed: value.toISOString().substring(0, 10),
                })
              }
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{ readOnly: true }}
            />
          </MuiPickersUtilsProvider>

          <TextField
            // hintText="Odometer"
            label="Odometer"
            type={"number"}
            fullWidth={true}
            margin="normal"
            value={serviceRecord.odometer}
            onChange={(event) =>
              setServiceRecord({
                ...serviceRecord,
                odometer: parseInt(event.target.value),
              })
            }
          />

          {serviceTypeList && (
            <Autocomplete
              id="record-type"
              options={serviceTypeList}
              fullWidth={true}
              value={serviceRecord.record_type}
              onChange={(event, newValue) => {
                console.log(newValue);
                setServiceRecord({
                  ...serviceRecord,
                  record_type: newValue ? newValue : "",
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  label="Record Type"
                  fullWidth={true}
                />
              )}
            />
          )}

          {serviceRecord &&
            serviceRecord.record_type.toLowerCase().includes("service") &&
            serviceComponent()}
          {serviceRecord &&
            serviceRecord.record_type.toLowerCase().includes("maintenance") &&
            maintenanceComponent()}
          {serviceRecord &&
            serviceRecord.record_type.toLowerCase().includes("brakes") &&
            brakesComponents()}
          {serviceRecord &&
            serviceRecord.record_type.toLowerCase().includes("tyre") &&
            tyresComponents()}

          <div style={styles.buttons}>
            {/*<Link to="/">*/}
            {/*  <Button color={"primary"} style={{color: 'white'}} variant="contained" onClick={() => history.goBack()}>Cancel</Button>*/}
            {/*</Link>*/}

            <Button
              style={styles.saveButton}
              variant="contained"
              // type="submit"
              color="secondary"
              onClick={saveBtn}
            >
              Save
            </Button>
            {/*{*/}
            {/*  !history.location.state.newRecord &&*/}
            {/*  <Button*/}
            {/*    style={styles.deleteButton}*/}
            {/*    variant="contained"*/}
            {/*    // type="submit"*/}
            {/*    color="secondary"*/}
            {/*    onClick={deleteRecord}*/}
            {/*  >*/}
            {/*    Delete*/}
            {/*  </Button>*/}
            {/*}*/}
          </div>
        </form>
      )}
    </PageBase>
  );
};
const mapStateToProps = (state) => ({
  isSignedIn: state.isSignedIn,
  accessToken: state.accessToken,
  refreshToken: state.refreshToken,
});

export default connect(mapStateToProps)(
  withStyles(stripedTableStyle, {
    withTheme: true,
  })(ServiceRecordForm)
);
