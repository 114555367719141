import React, { useEffect, useState, useCallback } from "react";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import API from "../../../services/API";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogContentTextLineStyle = {
  flexDirection: "row",
  display: "flex",
  marginLeft: 20,
};

const VehicleHistoryModal = ({ open, setOpen, vehicleClicked }) => {
  const [vehicleHistory, setVehicleHistory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open) {
      getVecHistory(vehicleClicked); //.then((value) => console.log(value));
    }
  }, [open]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [open]);

  // FUNCTIONS

  async function getVecHistory(vehicleClicked) {
    setIsLoading(true);
    const url =
      process.env.REACT_APP_API_URL +
      "/VehicleTechnicalHistory/" +
      vehicleClicked +
      "/";
    console.log(url);
    const SRData = await API.getAPIData(url, "GET", null);
    if (SRData.status === 200) {
      let responseObjectTemp = await SRData.json();
      // console.log(responseObjectTemp);
      setVehicleHistory(responseObjectTemp);
    }
    setIsLoading(false);
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth={"sm"}
      fullWidth={true}
    >
      <DialogTitle id="alert-dialog-slide-title">
        {"Technical History Summary"}
      </DialogTitle>
      {isLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress style={{ position: "fixed" }} />
        </div>
      ) : (
        <DialogContent>
          <DialogContentText style={{ fontWeight: "bold" }}>
            Last service:
          </DialogContentText>
          <DialogContentText style={DialogContentTextLineStyle}>
            Date: &nbsp; {vehicleHistory && vehicleHistory.last_service_date}
          </DialogContentText>

          <DialogContentText style={DialogContentTextLineStyle}>
            Odometer reading: &nbsp;{" "}
            {vehicleHistory && vehicleHistory.last_service_odometer} km
          </DialogContentText>

          <DialogContentText style={{ fontWeight: "bold" }}>
            Last tyres job:
          </DialogContentText>
          <DialogContentText style={DialogContentTextLineStyle}>
            Date: &nbsp; {vehicleHistory && vehicleHistory.last_tyrejob_date}
          </DialogContentText>

          <DialogContentText style={DialogContentTextLineStyle}>
            Odometer reading: &nbsp;{" "}
            {vehicleHistory && vehicleHistory.last_tyrejob_odometer} km
          </DialogContentText>

          <DialogContentText style={{ fontWeight: "bold" }}>
            Last brakes job:
          </DialogContentText>
          <DialogContentText style={DialogContentTextLineStyle}>
            Date: &nbsp; {vehicleHistory && vehicleHistory.last_brakejob_date}
          </DialogContentText>

          <DialogContentText style={DialogContentTextLineStyle}>
            Odometer reading: &nbsp;{" "}
            {vehicleHistory && vehicleHistory.last_brakejob_odometer} km
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VehicleHistoryModal;
