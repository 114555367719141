import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListSubheader from "@material-ui/core/ListSubheader";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { LocalShipping } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import theme from "../../theme";
import API from "../../services/API";
import { connect } from "react-redux";

// Modals and Popups
import VehicleFormModal from "../VT/Modals/VehicleFormModal";
import ArchiveConfirmFormModal from "../VT/Modals/ArchiveConfirmFormModal";

const styles = {
  subheader: {
    fontSize: 24,
    fontWeight: 300,
    backgroundColor: theme.palette.secondary[600],
    color: "white",
  },
};

const RecentChanges = ({
  classes,
  data,
  title,
  dispatch,
  serviceRecordTableState,
  refreshPage,
  setRefreshPage,
  vehicleTableState,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [vehicleSelected, setVehicleSelected] = useState("");
  const open = Boolean(anchorEl);
  const history = useHistory();
  const [vehicleClicked, setVehicleClicked] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [newRecord, setNewRecord] = useState(false);
  const [openArchiveModal, setOpenArchiveModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const handleClick = (event, vehicleID) => {
    setAnchorEl(event.currentTarget);
    dispatch({
      type: "SERVICE_RECORD_TABLE_STATE",
      value: {
        vehicleFilterIds: [vehicleID],
        page: 0,
        rowsPerPage: serviceRecordTableState.rowsPerPage,
        recordTypeFilter: [],
      },
    });

    setVehicleSelected(vehicleID);
  };

  const viewServiceRecords = () => {
    if (vehicleSelected) {
      console.log(vehicleSelected);
      let regExp = /[a-zA-Z]/g;
      if (regExp.test(vehicleSelected)) {
        // Archived vehicle
        history.push("/service/archived", { vehicleSelected: vehicleSelected });
      } else {
        // Active vehicle
        history.push("/service/active", { vehicleSelected: vehicleSelected });
      }
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    if (vehicleClicked) {
      setModalOpen(true);
    }
  }, [vehicleClicked]);

  async function viewVehicleDetails() {
    setAnchorEl(null);
    let regExp = /[a-zA-Z]/g;
    const apiEnd = !regExp.test(vehicleSelected)
      ? "/Vehicles/"
      : "/ArchivedVehicles/";
    const tempUrl =
      process.env.REACT_APP_API_URL + apiEnd + vehicleSelected + "/";
    // console.log("Fetch URL", tempUrl);
    const response = await API.getAPIData(tempUrl, "GET", null);
    const vehicle = await response.json();
    // console.log(vehicle);
    setVehicleClicked(vehicle);
  }

  const onMenuClose = () => {
    setAnchorEl(null);
    setVehicleSelected(null);
  };

  const rightIconMenu = () => {
    return (
      <Menu elevation={2} anchorEl={anchorEl} open={open} onClose={onMenuClose}>
        <MenuItem onClick={viewServiceRecords}>
          View Vehicle Technical Records
        </MenuItem>
        <MenuItem onClick={viewVehicleDetails}>View Vehicle Details</MenuItem>
      </Menu>
    );
  };

  const iconButtonElement = (vehicleID) => {
    return (
      <div>
        <IconButton
          aria-label="More"
          aria-owns={open ? "long-menu" : null}
          aria-haspopup="true"
          onClick={(event) => handleClick(event, vehicleID)}
        >
          <MoreVertIcon color={"action"} />
        </IconButton>
      </div>
    );
  };

  function viewVehicleRecords(vehicleID) {
    let regExp = /[a-zA-Z]/g;
    if (!regExp.test(vehicleID)) {
      // console.log(vehicleTableState);
      dispatch({
        type: "SERVICE_RECORD_TABLE_STATE",
        value: {
          ...serviceRecordTableState,
          vehicleFilterIds: [vehicleID],
          recordTypeFilter: [],
          page: 0,
          rowsPerPage: vehicleTableState.rowsPerPage,
        },
      });
      history.push("/selectedVehicle");
    } else {
      // console.log(vehicleTableState);
      dispatch({
        type: "SERVICE_RECORD_TABLE_STATE",
        value: {
          ...serviceRecordTableState,
          vehicleFilterIds: [vehicleID],
          recordTypeFilter: [],
          page: 0,
          rowsPerPage: vehicleTableState.rowsPerPage,
        },
      });
      history.push("/service/archived");
    }
  }

  // async function archiveVehicle() {}

  return (
    <div>
      {/* TODO: Implement the ConfirmationPopup/Modal for Archiving and Deleting - VTT-51 */}
      <ArchiveConfirmFormModal
        open={openArchiveModal}
        setOpen={setOpenArchiveModal}
        newRecord={true}
        vehicleClicked={vehicleSelected}
        setRefreshPageSend={() => setRefreshPage(!refreshPage)}
      />

      <VehicleFormModal
        open={modalOpen}
        setOpen={setModalOpen}
        vehicleClicked={vehicleClicked}
        viewServiceRecordsSend={viewVehicleRecords}
        setVehicleClickedSend={() => setVehicleClicked(null)}
        setRefreshPageSend={() => setRefreshPage(!refreshPage)}
        newRecord={newRecord}
        setNewRecordSend={() => setNewRecord(false)}
      />
      {rightIconMenu()}
      <Paper>
        <List
          subheader={
            <ListSubheader classes={{ root: classes.subheader }}>
              {title}
            </ListSubheader>
          }
        >
          {data.map((item, idx) => (
            <ListItem
              button={true}
              onClick={() => viewVehicleRecords(item.vehicleID)}
            >
              <Avatar style={{ marginRight: "10px" }}>
                <LocalShipping />
              </Avatar>
              <ListItemText
                secondary={item.dateUpdated}
                primary={
                  "Vehicle " +
                  item.vehicleID +
                  " - " +
                  item.recordType +
                  (item.job ? " (" + item.description + ")" : "")
                }
              />
              <ListItemSecondaryAction>
                {iconButtonElement(item.vehicleID)}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Paper>
    </div>
  );
};

RecentChanges.propTypes = {
  data: PropTypes.array,
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  serviceRecordTableState: state.serviceRecordTableState,
  vehicleTableState: state.vehicleTableState,
});

export default connect(mapStateToProps)(withStyles(styles)(RecentChanges));
