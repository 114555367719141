import React from "react";
import ServiceRecords from "../../components/VT/ServiceRecords";

const ActiveServiceRecords = () => {
  // const history = useHistory();
  // const [vehicleSelected, setVehicleSelected] = useState(null);

  // useEffect(() => {
  //   console.log('STATE', history.location.state);
  //   if (history.location.state) {
  //     setVehicleSelected(history.location.state.vehicleSelected);
  //   }
  // }, []);

  return (
    <ServiceRecords
      apiEnd={"ServiceRecords"}
      serviceRecordActive={true}
      // filter={vehicleSelected}
    />
  );
};

export default ActiveServiceRecords;
