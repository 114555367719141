import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { Menu, MenuItem, Toolbar } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { withStyles } from "@material-ui/core/styles";
import store from "../redux/store";

const styles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    // marginLeft: theme.drawer.width,
    width: `calc(100% - ${theme.drawer.width}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  root: {
    width: "100%",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(9),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
});

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      mobileMoreAnchorEl: null,
    };
  }

  handleProfileMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleClose = () => {
    // store.dispatch({ type: "CLEAR"});
    store.dispatch({ type: "ACCESS", value: null });
    store.dispatch({ type: "REFRESH", value: null });
    store.dispatch({ type: "LOGIN", value: false });
    this.setState({ anchorEl: null });
  };

  render() {
    const { handleChangeNavDrawer, classes, navDrawerOpen } = this.props;

    const { anchorEl } = this.state;
    const isMenuOpen = Boolean(anchorEl);
    // const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.handleClose}>Sign out</MenuItem>
        {/*<MenuItem onClick={this.handleClose}>My account</MenuItem>*/}
      </Menu>
    );

    // const renderMobileMenu = (
    //   <Menu
    //     anchorEl={mobileMoreAnchorEl}
    //     anchorOrigin={{ vertical: "top", horizontal: "right" }}
    //     transformOrigin={{ vertical: "top", horizontal: "right" }}
    //     open={isMobileMenuOpen}
    //     onClose={this.handleMobileMenuClose}
    //   >
    //     <MenuItem>
    //       <IconButton color="inherit">
    //         <Badge
    //           className={classes.margin}
    //           badgeContent={4}
    //           color="secondary"
    //         >
    //           <MailIcon />
    //         </Badge>
    //       </IconButton>
    //       <p>Messages</p>
    //     </MenuItem>
    //     <MenuItem>
    //       <IconButton color="inherit">
    //         <Badge
    //           className={classes.margin}
    //           badgeContent={11}
    //           color="secondary"
    //         >
    //           <NotificationsIcon />
    //         </Badge>
    //       </IconButton>
    //       <p>Notifications</p>
    //     </MenuItem>
    //     <MenuItem onClick={this.handleProfileMenuOpen}>
    //       <IconButton color="inherit">
    //         <AccountCircle />
    //       </IconButton>
    //       <p>Profile</p>
    //     </MenuItem>
    //   </Menu>
    // );

    return (
      <div>
        <AppBar
          className={classNames(classes.appBar, {
            [classes.appBarShift]: navDrawerOpen,
          })}
        >
          <Toolbar>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Open drawer"
              onClick={handleChangeNavDrawer}
            >
              <MenuIcon />
            </IconButton>
            {/*<div className={classes.search} >*/}
            {/*  <div className={classes.searchIcon}>*/}
            {/*    <SearchIcon />*/}
            {/*  </div>*/}
            {/*  <InputBase*/}
            {/*    placeholder="Search…"*/}
            {/*    classes={{*/}
            {/*      root: classes.inputRoot,*/}
            {/*      input: classes.inputInput,*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</div>*/}
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {/*<IconButton color="inherit">*/}
              {/*  <Badge*/}
              {/*    className={classes.margin}*/}
              {/*    badgeContent={4}*/}
              {/*    color="secondary"*/}
              {/*  >*/}
              {/*    <MailIcon />*/}
              {/*  </Badge>*/}
              {/*</IconButton>*/}
              {/*<IconButton color="inherit">*/}
              {/*  <Badge*/}
              {/*    className={classes.margin}*/}
              {/*    badgeContent={17}*/}
              {/*    color="secondary"*/}
              {/*  >*/}
              {/*    <NotificationsIcon />*/}
              {/*  </Badge>*/}
              {/*</IconButton>*/}
              <IconButton
                aria-owns={isMenuOpen ? "material-appbar" : null}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              {/*<IconButton*/}
              {/*  aria-owns={isMenuOpen ? "material-appbar" : null}*/}
              {/*  aria-haspopup="true"*/}
              {/*  onClick={() =>*/}
              {/*    window.open("https://github.com/hanyuei/react-material-admin")*/}
              {/*  }*/}
              {/*  color="inherit"*/}
              {/*>*/}
              {/*  <GitHub />*/}
              {/*</IconButton>*/}
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-owns={isMenuOpen ? "material-appbar" : null}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
            {renderMenu}
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

Header.propTypes = {
  styles: PropTypes.object,
  handleChangeNavDrawer: PropTypes.func,
  classes: PropTypes.object,
  navDrawerOpen: PropTypes.bool,
};

export default withStyles(styles)(Header);
