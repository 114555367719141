const initialState = {
  vehicles: "vehicles",
  isSignedIn: false,
  accessToken: "",
  refreshToken: "",
  vehicleTableState: {
    vehicleFilterIds: [],
    page: 0,
    rowsPerPage: 50,
  },
  serviceRecordTableState: {
    vehicleFilterIds: [],
    page: 0,
    rowsPerPage: 50,
    recordTypeFilter: [],
  },
  // openConfirmation: false,
  // notificationMessage: "Success!",
  // notificationType: "success",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "VEHICLES": {
      return {
        ...state,
        vehicles: action.value,
      };
    }
    case "ACCESS": {
      return {
        ...state,
        accessToken: action.value,
      };
    }
    case "REFRESH": {
      return {
        ...state,
        refreshToken: action.value,
      };
    }
    case "LOGIN": {
      return {
        ...state,
        isSignedIn: action.value,
      };
    }
    case "VEHICLE_TABLE_STATE": {
      return {
        ...state,
        vehicleTableState: action.value,
      };
    }
    case "SERVICE_RECORD_TABLE_STATE": {
      return {
        ...state,
        serviceRecordTableState: action.value,
      };
    }
    case "NOTIFICATION": {
      return {
        ...state,
        openNotification: action.value,
        notificationMessage: action.message,
        notificationType: action.messageType,
      };
    }
    case "CLEAR": {
      return {
        ...initialState,
      };
    }
    case "CLEAR_SERVICE_RECORD_TABLE_STATE": {
      return {
        ...state,
        serviceRecordTableState: initialState.serviceRecordTableState,
      };
    }
    case "CLEAR_VEHICLE_TABLE_STATE": {
      return {
        ...state,
        vehicleTableState: initialState.vehicleTableState,
      };
    }
    default:
      return state;
  }
}
