import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import PageBase from "../../PageBase";
import TextField from "@material-ui/core/TextField";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { grey } from "@material-ui/core/colors";
import DateFnsUtils from "@date-io/date-fns";
import API from "../../../services/API";
import { Build, Delete } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = {
  toggleDiv: {
    marginTop: 20,
    marginBottom: 5,
  },
  toggleLabel: {
    color: grey[400],
    fontWeight: 100,
  },
  buttons: {
    marginTop: 30,
    float: "right",
    width: "100%",
  },
  saveButton: {
    marginLeft: 5,
  },
  deleteButton: {
    marginLeft: 5,
    backgroundColor: "#d22929",
  },
};

function VehicleFormModal({
  dispatch,
  open,
  setOpen,
  vehicleClicked,
  viewServiceRecordsSend,
  acrchiveVehicleSend,
  setVehicleClickedSend,
  setRefreshPageSend,
  newRecord,
  setNewRecordSend,
}) {
  const classes = useStyles();
  const [vehicleData, setVehicleData] = useState(null);
  const [availableVehicleList, setAvailableVehicleList] = useState(null);
  // const [isLoading, setIsLoading] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState(null);

  // const history = useHistory();

  useEffect(() => {
    if (open) {
      // console.log("first open");
      setVehicleData(vehicleClicked);
      if (newRecord) {
        // console.log("first open new record" + newRecord);
        getAvailableVehiclesList();
      }
    }
  }, [open]);

  async function getAvailableVehiclesList() {
    const url =
      process.env.REACT_APP_API_URL + "/ListVehicleNumbers/available/";
    const SRData = await API.getAPIData(url, "GET", null);
    if (SRData.status === 200) {
      let responseObjectTemp = await SRData.json();
      console.log(responseObjectTemp.unshift(""));
      setAvailableVehicleList(responseObjectTemp);
    }
  }

  const handleClose = () => {
    setOpen(false);
    setVehicleClickedSend();
    setNewRecordSend();
  };

  const handleCloseSaved = () => {
    // console.log(vehicleData);
    setOpen(false);
    saveBtn();
    setVehicleClickedSend();
    setRefreshPageSend();
    setNewRecordSend();
  };

  const additionalActions = [
    {
      icon: <Build />,
      actionToolTip: "View Technical Records",
      callback: vehicleData
        ? () => viewServiceRecordsSend(vehicleData.vehicle_ID)
        : null,
    },
    // {
    //   icon: <Delete />,
    //   actionToolTip: "Delete Vehicle",
    //   callback: vehicleData
    //     ? () => viewServiceRecordsSend(vehicleData.vehicle_ID)
    //     : null,
    // },
  ];

  async function saveBtn() {
    // console.log(vehicleData);
    const response = await API.getAPIData(
      vehicleData.url,
      newRecord ? "POST" : "PUT",
      vehicleData
    );
    // console.log(response);
    if (response.status === 201 || response.status === 200) {
      dispatch({ type: "CONFIRMATION", value: true });
    }
  }

  async function toggleVehicleStatus() {
    // console.log("Archiving vehicle: " + vehicleData.vehicle_ID);
    // setIsLoading(true);
    const body = {
      vehicle_ID: vehicleData.vehicle_ID,
    };
    const url =
      process.env.REACT_APP_API_URL +
      "/ArchivedVehicles/" +
      (vehicleData.active_inservice ? "archive/" : "unarchive/");
    const SRData = await API.getAPIData(url, "POST", body);
    if (SRData.status === 200) {
      await SRData.json();
    }
    // setIsLoading(false);

    // Force page refresh
    setRefreshPageSend();
    setOpen(false);
    setVehicleClickedSend();
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        TransitionComponent={Transition}
        maxWidth={"lg"}
        fullWidth={true}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {newRecord ? "Add Vehicle" : "Edit Vehicle"}
            </Typography>
          </Toolbar>
        </AppBar>
        {vehicleData && (
          <PageBase
            title={"Vehicle " + vehicleData.vehicle_ID}
            deleteRecord={toggleVehicleStatus}
            deleteTooltip={
              vehicleData.active_inservice
                ? "Archive Vehicle"
                : "Activate Vehicle"
            }
            // TODO: deleteEnabled set to true when implemented
            deleteEnabled={false}
            additionalActions={additionalActions}
            isArchive={vehicleData.active_inservice}
            newRecord={newRecord}
          >
            {vehicleData && (
              <div>
                {availableVehicleList && newRecord && (
                  <Autocomplete
                    id="vehicle-id"
                    options={availableVehicleList}
                    fullWidth={true}
                    value={vehicleData.vehicle_ID}
                    disabled={!newRecord}
                    onChange={(event, newValue) => {
                      // console.log(event);
                      setVehicleData({
                        ...vehicleData,
                        vehicle_ID: newValue,
                        // url: vehicleData.url + newValue + "/",
                      });
                      console.log(vehicleData);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        label="Vehicle ID"
                        fullWidth={true}
                      />
                    )}
                  />
                )}

                <TextField
                  label="Chassis Number"
                  fullWidth={true}
                  margin="normal"
                  value={vehicleData.chassis_no}
                  onChange={(event) =>
                    setVehicleData({
                      ...vehicleData,
                      chassis_no: event.target.value,
                    })
                  }
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    showTodayButton={true}
                    id="date-added"
                    label="Date Added"
                    format="d MMM, yyyy"
                    fullWidth={true}
                    value={vehicleData.date_added}
                    onChange={(value) =>
                      setVehicleData({
                        ...vehicleData,
                        date_added: value.toISOString(),
                      })
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputProps={{ readOnly: true }}
                  />

                  <KeyboardDatePicker
                    showTodayButton={true}
                    margin="normal"
                    id="date-in-service"
                    // Show TODAY button
                    label="Date In-service"
                    format="d MMM, yyyy"
                    fullWidth={true}
                    value={vehicleData.date_inservice}
                    onChange={(value) =>
                      setVehicleData({
                        ...vehicleData,
                        date_inservice: value.toISOString().substring(0, 10),
                      })
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputProps={{ readOnly: true }}
                  />

                  <KeyboardDatePicker
                    showTodayButton={true}
                    margin="normal"
                    id="date-out-service"
                    label="Date Out-service"
                    format="d MMM, yyyy"
                    fullWidth={true}
                    value={vehicleData.date_outservice}
                    onChange={(value) =>
                      setVehicleData({
                        ...vehicleData,
                        date_outservice: value.toISOString().substring(0, 10),
                      })
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputProps={{ readOnly: true }}
                  />

                  <TextField
                    label="Engine Number"
                    fullWidth={true}
                    margin="normal"
                    value={vehicleData.engine_no}
                    onChange={(event) =>
                      setVehicleData({
                        ...vehicleData,
                        engine_no: event.target.value,
                      })
                    }
                  />

                  <TextField
                    label="Model"
                    fullWidth={true}
                    margin="normal"
                    value={vehicleData.model}
                    onChange={(event) =>
                      setVehicleData({
                        ...vehicleData,
                        model: event.target.value,
                      })
                    }
                  />

                  <TextField
                    label="Service Interval"
                    type={"number"}
                    fullWidth={true}
                    margin="normal"
                    value={vehicleData.service_interval}
                    onChange={(event) =>
                      setVehicleData({
                        ...vehicleData,
                        service_interval: parseInt(event.target.value),
                      })
                    }
                  />

                  <KeyboardDatePicker
                    // showTodayButton={true}
                    margin="normal"
                    id="year"
                    views={["year"]}
                    label="Year"
                    format="yyyy"
                    value={"01/01/" + vehicleData.year_model}
                    onChange={(value) =>
                      setVehicleData({
                        ...vehicleData,
                        year_model: value.getFullYear(),
                      })
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputProps={{ readOnly: true }}
                  />
                </MuiPickersUtilsProvider>

                <div style={styles.buttons}>
                  <Button
                    style={styles.saveButton}
                    variant="contained"
                    // type="submit"
                    color="secondary"
                    onClick={handleCloseSaved}
                  >
                    Save
                  </Button>
                </div>
              </div>
            )}
          </PageBase>
        )}
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isSignedIn: state.isSignedIn,
  accessToken: state.accessToken,
  refreshToken: state.refreshToken,
  vehicleTableState: state.vehicleTableState,
  serviceRecordTableState: state.serviceRecordTableState,
});

export default connect(mapStateToProps)(
  withStyles({
    withTheme: true,
  })(VehicleFormModal)
);
