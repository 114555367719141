import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import TextField from '@material-ui/core/TextField';
import PageBase from "../../PageBase";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { grey } from "@material-ui/core/colors";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import API from "../../../services/API";
import Autocomplete from "@material-ui/lab/Autocomplete";

const stripedTableStyle = theme => ({
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
});

const HotKeyForm = ({ props, classes, accessToken, refreshToken, dispatch }) => {

  const history = useHistory();
  const styles = {
    toggleDiv: {
      marginTop: 20,
      marginBottom: 5
    },
    toggleLabel: {
      color: grey[400],
      fontWeight: 100
    },
    buttons: {
      marginTop: 30,
      float: "right",
      width: '100%'
    },
    saveButton: {
      marginLeft: 5
    },
    deleteButton: {
      marginLeft: 5,
      backgroundColor: '#d22929'
    }
  };

  const [hotKey, setHotKey] = useState(null);
  const [hotkeyMainOptions, setHotkeyMainOptions] = useState(null);

  useEffect(() => {
    console.log('STATE', history.location.state);
    if (history.location.state) {
      setHotKey(history.location.state.data);
      // const main_keys = [...new Set(history.location.state.hotkeys.map(item => item.hotkey_main))];
      const main_keys = history.location.state.hotkeys
        .filter((value, index, self) => self.map(x => x.hotkey_main).indexOf(value.hotkey_main) === index)
        .map(value => {
          return ({
            hotkey_main: value.hotkey_main,
            main_description: value.main_description
          });
        });
      // console.log(main_keys)
      setHotkeyMainOptions(main_keys);
      // setHotkeyList(history.location.state.hotkeys);
    }
  }, []);


  useEffect(() => {
    console.log('Updated hotKey', hotKey);
  }, [hotKey]);

  async function saveBtn() {
    const response = await API.getAPIData(
      hotKey.url,
      history.location.state.newRecord ? 'POST' : 'PUT',
      hotKey
    );
    console.log(response);
    if (response.status === 201 || response.status === 200) {
      dispatch({ type: "CONFIRMATION", value: true });
      history.goBack();
    }
  }

  async function deleteRecord() {
    const response = await API.getAPIData(
      hotKey.url,
      'DELETE',
      hotKey
    );
    console.log(response);
    // Delete was successful
    if (response.status === 204) {
      dispatch({ type: "CONFIRMATION", value: true });
      history.goBack();
    }
  }

  function goBack() {
    history.goBack();
  }

  return (
    <PageBase
      title="Add/Edit HotKey"
      navigation="Application / Form Page"
      goBack={goBack}
      deleteRecord={deleteRecord}
      deleteTooltip={'Delete Hotkey'}
      newRecord={history.location.state.newRecord}>
      {
        hotKey &&

        <form>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>

            {/*<KeyboardDatePicker*/}
            {/*  margin="normal"*/}
            {/*  id="date-added-hk"*/}
            {/*  label="Date Added"*/}
            {/*  format="d MMM, yyyy"*/}
            {/*  fullWidth={true}*/}
            {/*  value={hotKey.date_added}*/}
            {/*  onChange={value => setHotKey({...hotKey, date_added: value.toISOString()})}*/}
            {/*  KeyboardButtonProps={{*/}
            {/*    'aria-label': 'change date',*/}
            {/*  }}*/}
            {/*/>*/}

            {/*<KeyboardDatePicker*/}
            {/*  margin="normal"*/}
            {/*  id="date-updated-hk"*/}
            {/*  label="Date Updated"*/}
            {/*  format="d MMM, yyyy"*/}
            {/*  fullWidth={true}*/}
            {/*  value={hotKey.date_updated}*/}
            {/*  onChange={value => setHotKey({*/}
            {/*    ...hotKey,*/}
            {/*    date_updated: value.toISOString()*/}
            {/*  })}*/}
            {/*  KeyboardButtonProps={{*/}
            {/*    'aria-label': 'change date',*/}
            {/*  }}*/}
            {/*/>*/}

            {
              hotkeyMainOptions &&
              <Autocomplete
                id="combo-box-demo"
                options={hotkeyMainOptions}
                getOptionLabel={(option) => option.hotkey_main + (option.main_description ? " - " + option.main_description : "")}
                getOptionSelected={(option, value) => option.hotkey_main === value.hotkey_main}
                fullWidth={true}
                value={{ hotkey_main: hotKey.hotkey_main, main_description: hotKey.main_description }}
                onChange={(event, newValue) => {
                  if (newValue) {
                    console.log(newValue.hotkey_main);
                    setHotKey({ ...hotKey, hotkey_main: newValue.hotkey_main, main_description: newValue.main_description })
                  } else {
                    setHotKey({ ...hotKey, hotkey_main: "", main_description: "" })
                  }
                }}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    margin="normal"
                    label="Hotkey"
                    fullWidth={true}
                  />
                }
              />
            }

            <TextField
              label="Main Description"
              fullWidth={true}
              margin="normal"
              value={hotKey.main_description}
              disabled={true}
              onChange={event => setHotKey({ ...hotKey, main_description: event.target.value })}
            />

            <TextField
              label="Hotkey Tag"
              fullWidth={true}
              margin="normal"
              value={hotKey.hotkey_tag}
              onChange={event => setHotKey({ ...hotKey, hotkey_tag: event.target.value })}
            />



            <TextField
              label="Tag Description"
              fullWidth={true}
              margin="normal"
              value={hotKey.tag_description}
              onChange={event => setHotKey({ ...hotKey, tag_description: event.target.value })}
            />

            <div style={styles.buttons}>
              {/*<Link to="/">*/}
              {/*  <Button color={"primary"} style={{color: 'white'}} variant="contained" onClick={() => history.goBack()}>Cancel</Button>*/}
              {/*</Link>*/}

              <Button
                style={styles.saveButton}
                variant="contained"
                // type="submit"
                color="secondary"
                onClick={saveBtn}
              >
                Save
              </Button>
              {/*{*/}
              {/*  !history.location.state.newRecord &&*/}
              {/*  <Button*/}
              {/*    style={styles.deleteButton}*/}
              {/*    variant="contained"*/}
              {/*    // type="submit"*/}
              {/*    color="secondary"*/}
              {/*    onClick={deleteRecord}*/}
              {/*  >*/}
              {/*    Delete*/}
              {/*  </Button>*/}
              {/*}*/}
            </div>
          </MuiPickersUtilsProvider>
        </form>
      }
    </PageBase>
  );
};

const mapStateToProps = (state) => ({
  isSignedIn: state.isSignedIn,
  accessToken: state.accessToken,
  refreshToken: state.refreshToken
});

export default connect(mapStateToProps)(withStyles(stripedTableStyle,
  {
    withTheme: true
  }
)(HotKeyForm));
