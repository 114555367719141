import React, { useEffect, useState } from "react";
import ServiceRecords from "../../components/VT/ServiceRecords";
import { useHistory } from "react-router-dom";

const ArchivedServiceRecords = () => {
  const history = useHistory();
  const [vehicleSelected, setVehicleSelected] = useState(null);

  useEffect(() => {
    console.log("STATE", history.location.state);
    if (history.location.state) {
      setVehicleSelected(history.location.state.vehicleSelected);
    }
  }, []);

  return (
    <ServiceRecords
      apiEnd={"ArchivedServiceRecords/"}
      serviceRecordActive={false}
      // filter={vehicleSelected}
    />
  );
};

export default ArchivedServiceRecords;
