import React, { useEffect, useState } from "react";
import globalStyles from "../styles";
import API from "../services/API";
import { Backdrop, CircularProgress } from "@material-ui/core";
import RecentChanges from "../components/dashboard/RecentChanges";
import moment from "moment";
const DashboardPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [recentChanges, setRecentChanges] = useState(null);
  const [refreshPage, setRefreshPage] = useState(false);

  useEffect(() => {
    async function getRecentChanges() {
      setIsLoading(true);
      const url = process.env.REACT_APP_API_URL + "/MostRecentChanges/";
      const SRData = await API.getAPIData(url, "GET", null);
      if (SRData.status === 200) {
        let responseObjectTemp = await SRData.json();
        setRecentChanges(
          responseObjectTemp.results.map((value) => {
            const date = new Date(value.date_updated);
            const dateStringWithTime = moment(date).format(
              "dddd DD MMMM YYYY HH:MM:SS"
            );
            return {
              vehicleID: value.vehicle_ID,
              recordType: value.record_type,
              description: value.description_tag,
              dateUpdated: dateStringWithTime,
            };
          })
        );
        // setVehicleList(responseObjectTemp);
      }
      setIsLoading(false);
    }

    getRecentChanges().then((value) => console.log(value));
  }, [refreshPage]);

  return (
    <div>
      <h3 style={globalStyles.navigation}>Application / Dashboard</h3>
      {isLoading ? (
        <Backdrop
          style={{ color: "#fff", zIndex: 2000 }}
          open={isLoading}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        recentChanges && (
          <RecentChanges
            title={"Recent Changes"}
            data={recentChanges}
            refreshPage={refreshPage}
            setRefreshPage={setRefreshPage}
          />
        )
      )}
    </div>
  );
};

export default DashboardPage;
