import React, { useEffect, useState, useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import PageBase from "../../PageBase";
import TextField from "@material-ui/core/TextField";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { grey } from "@material-ui/core/colors";
import DateFnsUtils from "@date-io/date-fns";
import API from "../../../services/API";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import { Close } from "@material-ui/icons";
import { Paper } from "@material-ui/core";
import globalStyles from "../../../styles";

const stripedTableStyle = (theme) => ({
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
});

const styles = {
  toggleDiv: {
    marginTop: 20,
    marginBottom: 5,
  },
  toggleLabel: {
    color: grey[400],
    fontWeight: 100,
  },
  buttons: {
    marginTop: 30,
    float: "right",
    width: "100%",
  },
  saveButton: {
    marginLeft: 5,
  },
  deleteButton: {
    marginLeft: 5,
    backgroundColor: "#d22929",
  },
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ServiceModal({
  dispatch,
  open,
  setOpen,
  vehicleClicked,
  setVehicleClickedSend,
  setRefreshPageSend,
  newRecord,
  setNewRecordSend,
}) {
  const classes = useStyles();
  // const [isLoading, setIsLoading] = useState(false);
  const [serviceRecord, setServiceRecord] = useState(null);
  const [availableVehicleList, setAvailableVehicleList] = useState(null);
  const [hotkeys, setHotkeys] = useState(null);
  const [serviceTypeList, setServiceTypeList] = useState(null); // List of available service types
  const hotKeysObject = {
    hotkey: "General",
    description: "",
    remarks: "",
  };
  const [jobEntryList, setJobEntryList] = useState(null);

  useLayoutEffect(() => {
    // console.log("test 1");
    if (open) {
      // console.log("this is the first");
      console.log(vehicleClicked);
      // console.log("first open" + newRecord);

      fetchHotkeys(); //.then((value) => console.log("fetch Hotkeys " + value));
      getServiceTypeList(); //.then((value) =>
      //  console.log("fetch ServiceTypeList " + value)
      // );

      if (newRecord) {
        // console.log("first open new record" + newRecord);
        getAvailableVehiclesList(); //.then((value) => console.log(value));
      } else {
        setAvailableVehicleList([
          vehicleClicked ? vehicleClicked.vehicleID : null,
        ]);
        getAvailableVehiclesList();
      }

      // If it's empty, use empty object
      if (vehicleClicked) {
        setServiceRecord(vehicleClicked);
        if (vehicleClicked && vehicleClicked.job_data.length !== 0) {
          setJobEntryList(vehicleClicked.job_data);
        } else {
          setJobEntryList([hotKeysObject]);
        }
      }
    }
  }, [open]);

  async function getAvailableVehiclesList() {
    const url = process.env.REACT_APP_API_URL + "/ListVehicleNumbers/active";
    const SRData = await API.getAPIData(url, "GET", null);
    if (SRData.status === 200) {
      let responseObjectTemp = await SRData.json();
      // console.log(responseObjectTemp.unshift(""));
      setAvailableVehicleList(responseObjectTemp);
    }
  }

  async function fetchHotkeys() {
    const tempUrl = process.env.REACT_APP_API_URL + "/Hotkeys/";
    // console.log("Fetch URL", tempUrl);
    const SRData = await API.getAPIData(tempUrl, "GET", null);
    let responseObjectTemp = await SRData.json();
    responseObjectTemp = responseObjectTemp.map((value) => ({
      hotkey: value.tag_description
        ? value.tag_description + " (" + value.hotkey_tag + ")"
        : "General",
      description: value.main_description,
      remarks: value.tag_description,
    }));
    // console.log(responseObjectTemp.unshift(hotKeysObject));
    setHotkeys(responseObjectTemp);
  }

  async function getServiceTypeList() {
    const url = process.env.REACT_APP_API_URL + "/ListServiceRecordsTypes/";
    const SRData = await API.getAPIData(url, "GET", null);
    if (SRData.status === 200) {
      let responseObjectTemp = await SRData.json();
      // console.log("setServiceTypeList", responseObjectTemp.unshift(""));
      setServiceTypeList(responseObjectTemp);
    }
  }

  const handleClose = () => {
    setJobEntryList(null);
    setServiceRecord(null);
    setOpen(false);
    setVehicleClickedSend();
    setNewRecordSend();
  };

  const handleCloseSaved = () => {
    // console.log(vehicleData);
    saveBtn();
    setJobEntryList(null);
    setServiceRecord(null);
    setRefreshPageSend();
    setOpen(false);
    setVehicleClickedSend();
    setNewRecordSend();
  };

  function getVehicle(vehicleID) {
    // console.log("test 3");
    const tempUrl = process.env.REACT_APP_API_URL + "/Vehicles/" + vehicleID;
    // console.log("Fetch URL", tempUrl);
    const SRData = API.getAPIData(tempUrl, "GET", null);
    SRData.then((value) => {
      value.json().then((value1) => {
        // console.log(value1);
        setServiceRecord({
          ...serviceRecord,
          chassis_no: value1.chassis_no,
          vehicle_ID: vehicleID,
        });
      });
    }).catch((reason) => {
      console.log(reason);
    });
  }

  async function saveBtn() {
    serviceRecord.job_data = jobEntryList;
    serviceRecord.job_data.pop(); // Remove last empty record
    const response = await API.getAPIData(
      serviceRecord.url,
      newRecord ? "POST" : "PUT",
      serviceRecord
    );
    // console.log("response " + response);
    if (response.status === 201 || response.status === 200) {
      dispatch({ type: "CONFIRMATION", value: true });
    }
  }

  async function deleteRecord() {
    const response = await API.getAPIData(
      serviceRecord.url,
      "DELETE",
      serviceRecord
    );
    // console.log(response);
    // Delete was successful
    if (response.status === 204) {
      dispatch({ type: "CONFIRMATION", value: true });
      setJobEntryList(null);
      setServiceRecord(null);
      setRefreshPageSend();
      setOpen(false);
      setVehicleClickedSend();
      setNewRecordSend();
    }
  }

  function removeJobItem(index) {
    // console.log(index);
    let tempJobList = [...jobEntryList]; // Make copy
    if (tempJobList && tempJobList.length > 1) {
      // console.log(tempJobList.splice(index,index));
      tempJobList.splice(index, 1);
      // console.log("tempJobList", tempJobList);
      setJobEntryList(tempJobList);
    } else {
      setJobEntryList([hotKeysObject]); // Empty object
    }
  }

  // // Use effect to add empty object
  useEffect(() => {
    //console.log("test 2");
    //console.log(jobEntryList);
    // Get last element in job list array
    const lastItem = jobEntryList
      ? jobEntryList[jobEntryList.length - 1]
      : hotKeysObject;

    // If there's something inside, add an empty object at the end
    if (
      lastItem.hotkey !== "General" ||
      lastItem.remarks ||
      lastItem.description
    ) {
      let tempJobList = [...jobEntryList]; // Make copy
      tempJobList.push(hotKeysObject);
      setJobEntryList(tempJobList);
    }
  }, [jobEntryList]);

  function jobEntryFunc(index) {
    // const [test, setTest] = useState('');
    // console.log([hotkeys, hotKeysObject, ...serviceRecord.job_data]);
    // console.log("test jobEntry");
    return (
      hotkeys && (
        <div
          key={index}
          style={{ flexDirection: "row", display: "flex", marginTop: 20 }}
        >
          <Paper
            elevation={3}
            style={{
              paddingLeft: 20,
              paddingBottom: 20,
              paddingRight: 20,
              flex: 1,
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <Autocomplete
                  id="combo-box-demo"
                  options={hotkeys}
                  getOptionLabel={(option) => option.hotkey}
                  getOptionSelected={(option, value) => {
                    return option.hotkey === value.hotkey;
                  }}
                  fullWidth={true}
                  value={jobEntryList[index]}
                  onChange={(event, newValue) => {
                    // console.log(newValue);
                    let tempJobList = [...jobEntryList]; // Make copy
                    // If newValue is not null, gooi newValue. Otherwise, gooi empty object
                    tempJobList[index] = newValue ? newValue : hotKeysObject;
                    setJobEntryList(tempJobList);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      label="Hotkey"
                      fullWidth={true}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <TextField
                  // hintText="Service Job Number"
                  label="Job Description"
                  fullWidth={true}
                  margin="normal"
                  value={jobEntryList[index].description}
                  onChange={(event) => {
                    // console.log(jobEntryList);
                    let tempJobList = [...jobEntryList]; // Make copy
                    tempJobList[index].description = event.target.value;
                    setJobEntryList(tempJobList);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <TextField
                  // hintText="Service Job Number"
                  label="Remarks"
                  fullWidth={true}
                  margin="normal"
                  multiline={true}
                  value={jobEntryList[index].remarks}
                  onChange={(event) => {
                    let tempJobList = [...jobEntryList]; // Make copy
                    tempJobList[index].remarks = event.target.value;
                    setJobEntryList(tempJobList);
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
          <div style={{ alignItems: "center", display: "flex" }}>
            <IconButton
              aria-label="delete"
              onClick={() => removeJobItem(index)}
            >
              <Close />
            </IconButton>
          </div>
        </div>
      )
    );
  }

  function brakesComponents() {
    return (
      <>
        {jobEntryList && jobEntryList[0] && (
          <>
            <TextField
              label="Description"
              fullWidth={true}
              margin="normal"
              value={jobEntryList[0].description}
              onChange={(event) => {
                let tempJobList = [...jobEntryList]; // Make copy
                tempJobList[0].description = event.target.value;
                tempJobList[0].hotkey = "Brake";
                setJobEntryList(tempJobList);
              }}
            />

            <TextField
              label="Remarks"
              fullWidth={true}
              margin="normal"
              value={jobEntryList[0].remarks}
              onChange={(event) => {
                let tempJobList = [...jobEntryList]; // Make copy
                tempJobList[0].remarks = event.target.value;
                tempJobList[0].hotkey = "Brake";
                setJobEntryList(tempJobList);
              }}
            />
          </>
        )}
      </>
    );
  }

  function tyresComponents() {
    return (
      <div>
        <TextField
          label="Tyre Size"
          fullWidth={true}
          margin="normal"
          value={serviceRecord.tyre_size}
          onChange={(event) =>
            setServiceRecord({
              ...serviceRecord,
              tyre_size: event.target.value,
            })
          }
        />

        <TextField
          label="Tyre Type"
          fullWidth={true}
          margin="normal"
          value={serviceRecord.tyre_type}
          onChange={(event) =>
            setServiceRecord({
              ...serviceRecord,
              tyre_type: event.target.value,
            })
          }
        />

        <TextField
          label="Tyre Pos"
          fullWidth={true}
          margin="normal"
          value={serviceRecord.tyre_pos}
          onChange={(event) =>
            setServiceRecord({ ...serviceRecord, tyre_pos: event.target.value })
          }
        />

        {jobEntryList[0] && (
          <TextField
            // hintText="Service Job Number"
            label="Remarks"
            fullWidth={true}
            margin="normal"
            value={jobEntryList[0].remarks}
            onChange={(event) => {
              let tempJobList = [...jobEntryList]; // Make copy
              tempJobList[0].remarks = event.target.value;
              tempJobList[0].description = "Tyre";
              tempJobList[0].hotkey = "Tyre";
              setJobEntryList(tempJobList);
            }}
          />
        )}
      </div>
    );
  }

  function serviceComponent() {
    return (
      <div>
        <TextField
          label="Service Type"
          fullWidth={true}
          margin="normal"
          value={serviceRecord.service_type}
          onChange={(event) =>
            setServiceRecord({
              ...serviceRecord,
              service_type: event.target.value,
            })
          }
        />
        <TextField
          // hintText="Service Job Number"
          label="Service Job Number"
          fullWidth={true}
          margin="normal"
          value={serviceRecord.service_jobno}
          onChange={(event) =>
            setServiceRecord({
              ...serviceRecord,
              service_jobno: event.target.value,
            })
          }
        />
        <TextField
          // hintText="Service Brakes"
          label="Brakes (%)"
          fullWidth={true}
          margin="normal"
          type={"number"}
          value={serviceRecord.service_brakes}
          onChange={(event) =>
            setServiceRecord({
              ...serviceRecord,
              service_brakes: parseInt(event.target.value),
            })
          }
        />
        <div style={{ marginTop: 30 }}>
          <h3 style={globalStyles.title}>Jobs</h3>
        </div>
        {jobEntryList &&
          jobEntryList.map((value, index) => {
            // console.log("Service jobEntryList " + index);
            return jobEntryFunc(index);
          })}
      </div>
    );
  }

  function maintenanceComponent() {
    return (
      jobEntryList && (
        <div>
          <div style={{ marginTop: 30 }}>
            <h3 style={globalStyles.title}>Jobs</h3>
          </div>
          {jobEntryList.map((value, index) => {
            // console.log("Maintenance jobEntryList " + index);
            return jobEntryFunc(index);
          })}
        </div>
      )
    );
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        TransitionComponent={Transition}
        maxWidth={"md"}
        fullWidth={true}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {newRecord ? "Add Service Record" : "Edit Service Record"}
            </Typography>
          </Toolbar>
        </AppBar>
        {
          <PageBase
            title="Service Record"
            // navigation="Application / Form Page"
            // goBack={goBack}
            deleteRecord={deleteRecord}
            deleteTooltip={"Delete"}
            newRecord={newRecord}
            isDelete
          >
            {serviceRecord && (
              <form>
                {availableVehicleList && (
                  <Autocomplete
                    id="vehicle-id"
                    options={
                      true ? availableVehicleList : [serviceRecord.vehicle_ID]
                    }
                    fullWidth={true}
                    value={serviceRecord.vehicle_ID}
                    disabled={false}
                    onChange={(event, newValue) => {
                      // console.log(newValue);
                      if (newValue) {
                        setServiceRecord({
                          ...serviceRecord,
                          vehicle_ID: newValue,
                        });
                        getVehicle(newValue);
                      } else {
                        // Vehicle ID was cleared, also clear chassis ID
                        setServiceRecord({
                          ...serviceRecord,
                          chassis_no: "",
                          vehicle_ID: "",
                        });
                        // setServiceRecord({...serviceRecord, vehicle_ID: ""})
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        label="Vehicle ID"
                        fullWidth={true}
                      />
                    )}
                  />
                )}

                <TextField
                  // hintText="Chassis Number"
                  label="Chassis Number"
                  fullWidth={true}
                  margin="normal"
                  value={serviceRecord.chassis_no}
                  disabled={true}
                  onChange={(event) =>
                    setServiceRecord({
                      ...serviceRecord,
                      chassis_no: event.target.value,
                    })
                  }
                />

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-completed"
                    label="Date Completed"
                    format="d MMM, yyyy"
                    fullWidth={true}
                    showTodayButton
                    value={serviceRecord.date_completed}
                    onChange={(value) =>
                      setServiceRecord({
                        ...serviceRecord,
                        date_completed: value.toISOString().substring(0, 10),
                      })
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputProps={{ readOnly: true }}
                  />
                </MuiPickersUtilsProvider>
                {/* <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    value={values.weight}
                    onChange={handleChange("weight")}
                    endAdornment={
                      <InputAdornment position="end">kg</InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                  <FormHelperText id="outlined-weight-helper-text">
                    Weight
                  </FormHelperText>
                </FormControl> */}
                <TextField
                  // hintText="Odometer"
                  label="Odometer"
                  type={"number"}
                  fullWidth={true}
                  margin="normal"
                  value={serviceRecord.odometer}
                  onChange={(event) =>
                    setServiceRecord({
                      ...serviceRecord,
                      odometer: parseInt(event.target.value),
                    })
                  }
                />

                {serviceTypeList && (
                  <Autocomplete
                    id="record-type"
                    options={serviceTypeList}
                    fullWidth={true}
                    value={serviceRecord.record_type}
                    onChange={(event, newValue) => {
                      // console.log(newValue);
                      setServiceRecord({
                        ...serviceRecord,
                        record_type: newValue ? newValue : "",
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        label="Record Type"
                        fullWidth={true}
                      />
                    )}
                  />
                )}

                {serviceRecord.record_type &&
                  serviceRecord.record_type.toLowerCase().includes("service") &&
                  serviceComponent()}
                {serviceRecord.record_type &&
                  serviceRecord.record_type
                    .toLowerCase()
                    .includes("maintenance") &&
                  maintenanceComponent()}
                {serviceRecord.record_type &&
                  serviceRecord.record_type.toLowerCase().includes("brakes") &&
                  brakesComponents()}
                {serviceRecord.record_type &&
                  serviceRecord.record_type.toLowerCase().includes("tyre") &&
                  tyresComponents()}

                <div style={styles.buttons}>
                  <Button
                    style={styles.saveButton}
                    variant="contained"
                    color="secondary"
                    onClick={handleCloseSaved}
                  >
                    Save
                  </Button>
                </div>
              </form>
            )}
          </PageBase>
        }
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isSignedIn: state.isSignedIn,
  accessToken: state.accessToken,
  refreshToken: state.refreshToken,
  vehicleTableState: state.vehicleTableState,
  serviceRecordTableState: state.serviceRecordTableState,
});

export default connect(mapStateToProps)(
  withStyles(stripedTableStyle, {
    withTheme: true,
  })(ServiceModal)
);
