import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import API from "../../services/API";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Chip from "@material-ui/core/Chip";
import ConfirmationPopup from "./ConfirmationPopup";
import IconButton from "@material-ui/core/IconButton";
import { Add, Clear } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
// import
import { saveAs } from "file-saver";
import { write, utils } from "xlsx";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

const stripedTableStyle = (theme) => ({
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
});

const Hotkeys = ({ dispatch }) => {
  const history = useHistory();
  const [currentDataSet, setCurrentDataSet] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [responseObject, setResponseObject] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [selectedHotkeys, setSelectedHotkeys] = useState(null);
  const [newHotkeyMain, setNewHotkeyMain] = useState("");
  const [newHotkeyMainDescription, setNewHotkeyMainDescription] = useState("");
  const [newHotkeyOpen, setNewHotkeyOpen] = useState(false);
  const [subHotkeyOpen, setSubHotkeyOpen] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [mainHotkeySelected, setMainHotkeySelected] = useState(null);
  const [subHotkeyTag, setSubHotkeyTag] = useState("");
  const [subHotkeyDescription, setSubHotkeyDescription] = useState("");

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTableHead: {
          root: { backgroundColor: "#666" },
        },
        MUIDataTableHeadCell: {
          fixedHeader: {
            backgroundColor: "#666",
            color: "#FFF",
          },
          toolButton: {
            backgroundColor: "#666",
            color: "#FFF",
            fillColor: "#fff",
          },
          sortActive: { backgroundColor: "#666", color: "#FFF" },
          sortLabelRoot: { color: "#FFF" },
        },
        // MuiTableSortLabel: {
        //   active: { fillColor: "#fff" },
        // },
        // MUIDataTable: { responsiveScroll: { class: "card" } },
        MUIDataTableSelectCell: {
          headerCell: {
            backgroundColor: "#666",
            color: "#fff",
          },
          checkBoxRoot: {
            color: "#fff",
          },
        },
        MUIDataTableBodyCell: {
          root: {
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      },
    });

  useEffect(() => {
    async function getHotkeys() {
      setIsLoading(true);
      const url = process.env.REACT_APP_API_URL + "/Hotkeys/";
      const SRData = await API.getAPIData(url, "GET", null);
      if (SRData.status === 200) {
        let responseObjectTemp = await SRData.json();
        console.log(responseObjectTemp);
        console.log(currentDataSet);
        setResponseObject(responseObjectTemp);
      }
      setIsLoading(false);
    }

    getHotkeys().then((value) => console.log(value));
  }, [reloadPage]);

  function uniqueByKey(array, key) {
    return [...new Map(array.map((x) => [x[key], x])).values()];
  }

  useEffect(() => {
    console.log("HERE");
    if (responseObject) {
      setCurrentDataSet(uniqueByKey(responseObject, "hotkey_main"));
    }
  }, [responseObject]);

  const options = {
    fixedHeader: true,
    filterType: "checkbox",
    responsive: "standard",
    fixedSelectColumn: false,
    tableBodyHeight: "80vh",
    pagination: false,
    // onRowClick: (rowData, rowMeta) => handleCellClick(rowMeta.dataIndex),
    onRowsDelete: (rowsDeleted) => {
      handleMainHotkeyDelete(); // Open modal
      // In the meantime, populate "selectedHotkeys"
      let hotkeysToDelete = [];
      rowsDeleted.data.forEach((tableRowObject) => {
        let mainHotkey = currentDataSet[tableRowObject.dataIndex];

        // Push all the children hotkeys
        responseObject.forEach(
          (value) =>
            value.hotkey_main === mainHotkey.hotkey_main &&
            hotkeysToDelete.push(value)
        );
      });
      setSelectedHotkeys(hotkeysToDelete);
    },
    print: false,
    customToolbar: () => {
      return (
        // <CustomToolbar addNew={addNew}/>
        <Fab
          color="inherit"
          size="small"
          aria-label="add"
          variant="extended"
          // style={
          //   {
          //     //   position: "fixed",
          //     //   bottom: "2rem",
          //     //   right: "2rem",
          //   }
          // }
          onClick={addNew}
        >
          <AddIcon />
        </Fab>
      );
    },
    onDownload: (buildHead, buildBody, columns, values) => {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      // build the json, this is one way to do it
      console.log(values.forEach((val) => console.log(val)));

      const json = values.reduce((result, val) => {
        const temp = {};
        val.data.forEach((v, idx) => {
          temp[columns[idx].name] = v;
        });
        result.push(temp);
        return result;
      }, []);

      const fileName = `Hotkeys`;
      const ws = utils.json_to_sheet(json);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      saveAs(data, fileName + fileExtension);
      // cancel default  CSV download from table
      return false;
    },
    textLabels: {
      toolbar: {
        downloadCsv: "Download XLSX",
      },
    },
  };

  // const handleDelete = (chipToDelete) => () => {
  //   console.log(chipToDelete);
  // };

  async function handleDelete(hotKey) {
    setTitle("Delete Hotkey Tag");
    setMessage("Are you sure you would like to delete this hotkey tag?");
    setOpenModal(true);
    setSelectedHotkeys([hotKey]);
  }

  function handleMainHotkeyDelete() {
    setTitle("Delete Hotkeys");
    setMessage(
      "Are you sure you would like to delete the specified main hotkeys and their related tags?"
    );
    setOpenModal(true);
  }

  function handleOnClick(hotkey) {
    history.push("/hotKeyForm", { data: hotkey, hotkeys: currentDataSet });
  }

  const hotkeyChipList = (index) => {
    function getHotkeyTags() {
      return responseObject
        .filter(
          (value) => value.hotkey_main === currentDataSet[index].hotkey_main
        )
        .map((value, index) => {
          if (value.hotkey_tag) {
            function returnFormattedText() {
              return (
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <div style={{ fontWeight: 600 }}>
                    {value.hotkey_tag + ":"}
                  </div>
                  &nbsp;
                  {value.tag_description}
                </div>
              );
            }

            return (
              <Chip
                key={index}
                style={{ marginLeft: 5, marginBottom: 5 }}
                // icon={icon}
                // Changed deleteIcon
                deleteIcon={<Clear />}
                label={returnFormattedText()}
                onDelete={() => handleDelete(value)}
                onClick={() => handleOnClick(value)}
                // className={classes.chip}
              />
            );
          }
          return <div />;
        });
    }

    return <div>{getHotkeyTags()}</div>;
  };

  function hotkeyAddIcon(index) {
    return (
      <div>
        <IconButton
          aria-label="More"
          // aria-owns={open ? "long-menu" : null}
          aria-haspopup="true"
          onClick={() => addNewHotkeyTag(currentDataSet[index])}
        >
          <Add color={"action"} />
        </IconButton>
        {/*{rightIconMenu(index)}*/}
      </div>
    );
  }

  const columns = [
    {
      name: "hotkey_main",
      label: "Hotkey Main",
      options: {
        filter: true,
        sort: true,
        filterType: "dropdown",
        filterOptions: {
          fullWidth: true,
        },
      },
    },
    {
      name: "main_description",
      label: "Main Description",
      options: {
        filter: true,
        sort: true,
        filterType: "dropdown",
        filterOptions: {
          fullWidth: true,
        },
      },
    },
    {
      name: "hotkey_tags",
      label: "Hotkey Tags",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => hotkeyChipList(dataIndex),
        viewColumns: false,
      },
    },
    {
      name: "add_hotkey_tags",
      label: " ",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => hotkeyAddIcon(dataIndex),
        viewColumns: false,
      },
    },
  ];

  function addNew() {
    setNewHotkeyOpen(true);
  }

  async function saveMainHotkey() {
    let hotKey = {
      hotkey_main: newHotkeyMain,
      main_description: newHotkeyMainDescription,
    };

    const response = await API.getAPIData(
      process.env.REACT_APP_API_URL + "/Hotkeys/",
      "POST",
      hotKey
    );
    console.log(response);
    if (response.status === 201 || response.status === 200) {
      dispatch({ type: "CONFIRMATION", value: true });
      setReloadPage(!reloadPage);
    }

    setNewHotkeyOpen(false);
  }

  async function saveSubHotkey() {
    setSubHotkeyOpen(false);
    setIsLoading(true);

    let hotKey = {
      hotkey_main: mainHotkeySelected.hotkey_main,
      main_description: mainHotkeySelected.main_description,
      hotkey_tag: subHotkeyTag,
      tag_description: subHotkeyDescription,
    };

    const response = await API.getAPIData(
      process.env.REACT_APP_API_URL + "/Hotkeys/",
      "POST",
      hotKey
    );
    console.log(response);
    if (response.status === 201 || response.status === 200) {
      dispatch({ type: "CONFIRMATION", value: true });
      setReloadPage(!reloadPage);
    }
  }

  function mainHotkeyDialog() {
    return (
      <Dialog open={newHotkeyOpen} onClose={handleClose}>
        <DialogTitle>New Main Hotkey</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please specify a new main hotkey and provide a description.
          </DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
              <TextField
                margin="dense"
                id="tag"
                label="Main Tag"
                fullWidth
                color="secondary"
                onBlur={(event) => setNewHotkeyMain(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
              <TextField
                margin="dense"
                id="description"
                label="Main Description"
                fullWidth
                color="secondary"
                onBlur={(event) =>
                  setNewHotkeyMainDescription(event.target.value)
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={saveMainHotkey} color="secondary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  function subHotkeyDialog() {
    return (
      <Dialog open={subHotkeyOpen} onClose={handleClose}>
        <DialogTitle>
          New Sub-Hotkey for&nbsp;
          {mainHotkeySelected && mainHotkeySelected.hotkey_main}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please specify a new sub-hotkey and provide a description.
          </DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
              <TextField
                margin="dense"
                id="tag"
                label="Tag"
                fullWidth
                color="secondary"
                onBlur={(event) => setSubHotkeyTag(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
              <TextField
                margin="dense"
                id="description"
                label="Description"
                fullWidth
                color="secondary"
                onBlur={(event) => setSubHotkeyDescription(event.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={saveSubHotkey} color="secondary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  function handleClose() {
    setNewHotkeyOpen(false);
    setSubHotkeyOpen(false);
  }

  function addNewHotkeyTag(hotkey) {
    setMainHotkeySelected(hotkey);
    setSubHotkeyOpen(true);
  }

  async function deleteHotkeyTag(hotkey) {
    const response = await API.getAPIData(hotkey.url, "DELETE", hotkey);
    return response.status === 204;
  }

  const reducer = async (accumulator, currentValue) => {
    const success = await deleteHotkeyTag(currentValue);
    if (success) {
      (await accumulator).push(currentValue);
    }
    return accumulator;
  };

  async function handleYes() {
    let hotKeys = selectedHotkeys;
    setOpenModal(false);
    const reduced = await hotKeys.reduce(reducer, []);

    // If all was removed, then go for confirmation
    if (reduced.length === hotKeys.length) {
      dispatch({ type: "CONFIRMATION", value: true });
    }

    // Remove the ones that was deleted from current data
    let temp = [...responseObject];
    (await reduced).forEach((value) => {
      let index = temp.indexOf(value);
      if (index !== -1) {
        temp.splice(index, 1);
      }
    });
    setResponseObject(temp);
  }

  async function handleNo() {
    console.log("No");
    setOpenModal(false);
  }

  return (
    <div>
      <ConfirmationPopup
        open={openModal}
        handleYes={handleYes}
        handleNo={handleNo}
        title={title}
        message={message}
      />
      {mainHotkeyDialog()}
      {subHotkeyDialog()}
      <Backdrop
        style={{ color: "#fff", zIndex: 2000 }}
        open={isLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MuiThemeProvider theme={getMuiTheme}>
        <MUIDataTable
          title={"Hotkeys"}
          data={currentDataSet ? currentDataSet : []}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isSignedIn: state.isSignedIn,
  accessToken: state.accessToken,
  refreshToken: state.refreshToken,
});

export default connect(mapStateToProps)(
  withStyles(stripedTableStyle, { withTheme: true })(Hotkeys)
);
