import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import TruckBackground from "../images/LoginImage.jpg";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { connect } from "react-redux";
import API from "../services/API";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  banner: {
    elevation: 6,
    justifyContent: "center",
    display: "flex",
  },
  image: {
    // backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundImage: `url(${TruckBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function LoginMUI({ dispatch }) {
  const classes = useStyles();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [usernameMessage, setUsernameMessage] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState("");

  async function login() {
    try {
      let response = await API.loginAPI(username, password);
      console.log(response);
      const tokens = await response.json();
      console.log(tokens);
      if (response.status === 401) {
        setUsernameError(true);
        setPasswordError(true);
        setUsernameMessage(tokens.detail);
        setPasswordMessage(tokens.detail);
      } else if (response.status === 400) {
        setUsernameError(true);
        setPasswordError(true);
        setUsernameMessage(tokens.username);
        setPasswordMessage(tokens.password);
      } else if (response.status === 200) {
        dispatch({ type: "REFRESH", value: tokens.refresh });
        dispatch({ type: "LOGIN", value: true });
        dispatch({ type: "ACCESS", value: tokens.access });
      }
    } catch (e) {
      console.log("An error occurred", e);
    }
  }

   function checkEnterKey(key) {

    if (key === "Enter") {
      login();
    }
  }

  return (
    <div>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={false} md={7} className={classes.image} />
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          component={Paper}
          elevation={6}
          square
        >
          <AppBar className={classes.banner} position="sticky">
            <Toolbar>
              <Typography variant="h6">VT Technical</Typography>
            </Toolbar>
          </AppBar>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                error={usernameError}
                helperText={usernameMessage}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Username"
                name="username"
                // autoComplete="email"
                autoFocus
                onChange={(event) => {
                  setUsernameMessage("");
                  setUsernameError(false);
                  setUsername(event.target.value);
                }}
                onKeyUp={(event) => checkEnterKey(event.key)}
              />
              <TextField
                error={passwordError}
                helperText={passwordMessage}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(event) => {
                  setPasswordMessage("");
                  setPasswordError(false);
                  setPassword(event.target.value);
                }}
                onKeyUp={(event) => checkEnterKey(event.key)}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={login}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="password/reset/request" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                {/*{" "}
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>{" "}
            */}
              </Grid>
              {/*<Box mt={5}>*/}
              {/*  <Copyright />*/}
              {/*</Box>*/}
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isSignedIn: state.isSignedIn,
  accessToken: state.accessToken,
  refreshToken: state.refreshToken,
});

export default connect(mapStateToProps)(LoginMUI);
