import React from "react";

import {
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ReportProblemOutlined } from "@material-ui/icons";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const NotFoundPage = () => {
  const classes = useStyles();
  return (
    <div>
      {/* <Grid container spacing={1}>
        <Grid container item> */}
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <Typography variant="h3" align="center">
            <ReportProblemOutlined fontSize="inherit" />
          </Typography>
          <Typography variant="h3" align="center">
            Oops 404
          </Typography>
          <br />
          <Typography variant="h6" align="center">
            The URL you requested was not found at the moment.
          </Typography>
          <br />
          <Typography align="center">
            You can go back home to safety by clicking below or navigating back
            from where you came...
          </Typography>
        </CardContent>
        <CardActions>
          <Button variant="outlined" size="small">
            Go Home
          </Button>
        </CardActions>
      </Card>
      {/* </Grid>
      </Grid> */}
    </div>
  );
};

export default NotFoundPage;
