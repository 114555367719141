import React, { useEffect, useState } from "react";
import ServiceRecords from "../../components/VT/ServiceRecords";
import { connect } from "react-redux";
import API from "../../services/API";

const SelectedVehicle = ({
  dispatch,
  serviceRecordTableState,
  vehicleTableState,
}) => {
  // const history = useHistory();
  const [vehicleSelected, setVehicleSelected] = useState([]);
  const [serviceRecordSelectedActive, setServiceRecordSelectedActive] =
    useState(false);

  useEffect(() => {
    async function getVehicle() {
      const url = process.env.REACT_APP_API_URL + "/ListVehicleNumbers/active/";
      const SRData = await API.getAPIData(url, "GET", null);
      if (SRData.status === 200) {
        let responseObjectTemp = await SRData.json();
        console.log(responseObjectTemp);
        return responseObjectTemp[0];
      }
    }

    if (
      serviceRecordTableState.vehicleFilterIds === null ||
      serviceRecordTableState.vehicleFilterIds.length === 0
    ) {
      if (
        vehicleTableState.vehicleFilterIds === null ||
        vehicleTableState.vehicleFilterIds.length === 0
      ) {
        getVehicle().then((value) => {
          console.log(value);
          setVehicleSelected([value]);
          dispatch({
            type: "SERVICE_RECORD_TABLE_STATE",
            value: {
              ...serviceRecordTableState,
              vehicleFilterIds: [value],
            },
          });
        });
      } else {
        setVehicleSelected(vehicleTableState.vehicleFilterIds);
        dispatch({
          type: "SERVICE_RECORD_TABLE_STATE",
          value: {
            ...serviceRecordTableState,
            vehicleFilterIds: vehicleTableState.vehicleFilterIds,
          },
        });
      }
    } else {
      setVehicleSelected(serviceRecordTableState.vehicleFilterIds);
    }
    // console.log(vehicleSelected);
    // if (vehicleSelected <= "099") {
    //   setServiceRecordSelectedActive(true);
    // } else {
    //   setServiceRecordSelectedActive(false);
    // }
  }, []);

  return (
    <>
      {vehicleSelected.length > 0 && (
        <ServiceRecords
          apiEnd={"ServiceRecords/"}
          serviceRecordActive={true}
          vehicleSelected={vehicleSelected}
          // filter={vehicleSelected}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  serviceRecordTableState: state.serviceRecordTableState,
  vehicleTableState: state.vehicleTableState,
});

export default connect(mapStateToProps)(SelectedVehicle);
