import React from "react";

import Vehicles from "../../components/VT/Vehicles";

const ActiveVehicles = () => {

  return (
    <Vehicles
      apiEnd={'Vehicles/'}
      vehicleActive={true}
    />
  )
}

export default ActiveVehicles;
