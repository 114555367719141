import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { Archive, Check, Delete } from "@material-ui/icons";

const defaultToolbarSelectStyles = {
  iconButton: {
    marginRight: "24px",
  },
};

class CustomToolbarSelect extends React.Component {
  handleClick = () => {
    // console.log("clicked on icon!");
  };

  handleClickDeselectAll = () => {
    this.props.setSelectedRows([]);
  };

  render() {
    const {
      classes,
      toggleVehicleStatus,
      deleteVehicle,
      selectedRows,
      isArchiveIcon,
      title,
    } = this.props;

    function toggleVehicleStatuses() {
      selectedRows.data.forEach((value) =>
        toggleVehicleStatus(value.dataIndex)
      );
    }

    function deleteVehicles() {
      selectedRows.data.forEach((value) => deleteVehicle(value.dataIndex));
    }

    return (
      <div className={classes.iconContainer}>
        {/* <Tooltip title={"Deselect ALL"}>
          <IconButton
            className={classes.iconButton}
            onClick={this.handleClickDeselectAll}
          >
            <IndeterminateCheckBox className={classes.icon} />
          </IconButton>
        </Tooltip> */}
        <Tooltip title={title}>
          <IconButton
            className={classes.iconButton}
            onClick={toggleVehicleStatuses}
          >
            {isArchiveIcon ? (
              <Archive className={classes.icon} />
            ) : (
              <Check className={classes.icon} />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip title={"Delete Vehicle"}>
          {/* TODO: Implement Delete Vehicle - disabled for now */}
          <IconButton
            className={classes.iconButton}
            onClick={deleteVehicle}
            disabled={true}
          >
            <Delete className={classes.icon} />
          </IconButton>
        </Tooltip>
      </div>
    );
  }
}

export default withStyles(defaultToolbarSelectStyles, {
  name: "CustomToolbar",
})(CustomToolbarSelect);
