import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import TruckBackground from "../images/LoginImage.jpg";
import { connect } from "react-redux";
import API from "../services/API";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    // backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundImage: `url(${TruckBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const PasswordResetConfirmPage = () => {
  const classes = useStyles();

  const [newPasswordSet, setNewPasswordSet] = useState(false);

  const { id, token } = useParams();

  const [idError, setIdError] = useState(false);
  const [idMessage, setIdMessage] = useState("");

  const [tokenError, setTokenError] = useState(false);
  const [tokenMessage, setTokenMessage] = useState("");

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState("");

  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordConfirmError, setPasswordConfirmError] = useState(false);
  const [passwordConfirmMessage, setPasswordConfirmMessage] = useState("");

  async function submitPasswordResetConfirmation() {
    try {
      const body = {
        uid: id,
        token: token,
        new_password: password,
        re_new_password: passwordConfirm,
      };
      const url =
        "https://vttapi.intentio.co.za/auth/users/reset_password_confirm/";
      let response = await API.APICallUnauthorized(url, "POST", body);
      console.log(response);
      if (response.status === 401) {
        const tokens = await response.json();
        console.log(tokens);

        setIdError(true);
        setIdMessage(tokens.detail);

        setTokenError(true);
        setTokenMessage(tokens.detail);

        setPasswordError(true);
        setPasswordMessage(tokens.detail);

        setPasswordConfirmError(true);
        setPasswordConfirmMessage(tokens.detail);
      } else if (response.status === 400) {
        const tokens = await response.json();
        console.log(tokens);

        setIdError(true);
        setIdMessage(tokens.uid);

        setTokenError(true);
        setTokenMessage(tokens.token);

        setPasswordError(true);
        setPasswordMessage(tokens.new_password);

        setPasswordConfirmError(true);
        setPasswordConfirmMessage(tokens.re_new_password);
      } else if (response.status === 204) {
        console.log("New password set successfully");
        setNewPasswordSet(true);
      }
    } catch (e) {
      console.log("An error occurred", e);
    }
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Password reset confirmation
          </Typography>
          {newPasswordSet ? (
            <form className={classes.form} noValidate>
              <p>
                New password set successfully! You can now log in with your new
                password.
              </p>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/";
                }}
              >
                Login
              </Button>
            </form>
          ) : (
            <form className={classes.form} noValidate>
              <TextField
                error={idError}
                helperText={idMessage}
                margin="normal"
                required
                fullWidth
                name="uid"
                label="uid"
                id="uid"
                disabled
                value={id}
                onChange={(event) => {
                  setIdMessage("");
                  setIdError(false);
                }}
              />
              <TextField
                error={tokenError}
                helperText={tokenMessage}
                margin="normal"
                required
                fullWidth
                name="token"
                label="token"
                id="token"
                disabled
                value={token}
                onChange={(event) => {
                  setTokenMessage("");
                  setTokenError(false);
                }}
              />
              <TextField
                error={passwordError}
                helperText={passwordMessage}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="New Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(event) => {
                  setPasswordMessage("");
                  setPasswordError(false);
                  setPassword(event.target.value);
                }}
              />
              <TextField
                error={passwordConfirmError}
                helperText={passwordConfirmMessage}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="passwordConfirm"
                label="Confirm New Password"
                type="password"
                id="passwordConfirm"
                autoComplete="current-password"
                onChange={(event) => {
                  setPasswordConfirmMessage("");
                  setPasswordConfirmError(false);
                  setPasswordConfirm(event.target.value);
                }}
              />

              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={submitPasswordResetConfirmation}
              >
                Set new password
              </Button>
            </form>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

const PasswordResetRequest = ({ dispatch }) => {
  const classes = useStyles();

  const [emailSent, setEmailSent] = useState(false);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailMessage, setEmailMessage] = useState("");

  async function submitPasswordResetRequest() {
    try {
      const body = {
        email: email,
      };
      const url = "https://vttapi.intentio.co.za/auth/users/reset_password/";
      let response = await API.APICallUnauthorized(url, "POST", body);
      console.log(response);
      if (response.status === 401) {
        setEmailError(true);
      } else if (response.status === 400) {
        setEmailError(true);
      } else if (response.status === 204) {
        dispatch({ type: "EMAILSENT", value: true });
        setEmailSent(true);
      }
    } catch (e) {
      console.log("An error occurred", e);
    }
  }

  async function handleTryAgain() {
    setEmailSent(false);
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Password reset request
          </Typography>
          {emailSent ? (
            <form className={classes.form} noValidate>
              <p>
                An email with a password reset link was sent to you. Please open
                the link in the email to reset your password.
              </p>
              <p>Email not received?</p>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleTryAgain}
              >
                Try again
              </Button>
            </form>
          ) : (
            <form className={classes.form} noValidate>
              <TextField
                error={emailError}
                helperText={emailMessage}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                type="email"
                autoFocus
                onChange={(event) => {
                  setEmailMessage("");
                  setEmailError(false);
                  setEmail(event.target.value);
                }}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={submitPasswordResetRequest}
              >
                Reset password
              </Button>
              {/*<Box mt={5}>*/}
              {/*  <Copyright />*/}
              {/*</Box>*/}
            </form>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  emailSent: state.emailSent,
});

export default connect(mapStateToProps)(PasswordResetRequest);
export { PasswordResetConfirmPage };
