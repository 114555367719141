import React, { useEffect, useState, useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import PageBase from "../../PageBase";
import TextField from "@material-ui/core/TextField";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { grey } from "@material-ui/core/colors";
import DateFnsUtils from "@date-io/date-fns";
import API from "../../../services/API";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import { Close } from "@material-ui/icons";
import { Paper, FormControlLabel, Checkbox } from "@material-ui/core";
import globalStyles from "../../../styles";

const stripedTableStyle = (theme) => ({
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
});

const styles = {
  toggleDiv: {
    marginTop: 20,
    marginBottom: 5,
  },
  toggleLabel: {
    color: grey[400],
    fontWeight: 100,
  },
  buttons: {
    marginTop: 30,
    float: "right",
    width: "100%",
  },
  saveButton: {
    marginLeft: 5,
  },
  deleteButton: {
    marginLeft: 5,
    backgroundColor: "#d22929",
  },
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ArchiveModal({
  dispatch,
  open,
  setOpen,
  newRecord,
  vehicleClicked,
  setRefreshPageSend,
}) {
  const classes = useStyles();
  const [archiveRecord, setArchiveRecord] = useState(null);
  // const [vehicleData, setVehicleData] = useState(null);
  const [vehicleHistory, setVehicleHistory] = useState(null);
  const [checkboxState, setCheckboxState] = useState(null);

  // FUNCTIONS

  async function getVehicleHistory(vehicleSelected) {
    const url =
      process.env.REACT_APP_API_URL + "/Vehicles/" + vehicleSelected + "/";
    console.log(url);
    const SRData = await API.getAPIData(url, "GET", null);
    if (SRData.status === 200) {
      let responseObjectTemp = await SRData.json();
      setVehicleHistory(responseObjectTemp);
      console.log(responseObjectTemp);
    }
  }

  async function submitArchive() {
    // console.log("Archiving vehicle: " + currentDataSet[index].vehicle_ID);
    var messageValue =
      "Vehicle with ID: " +
      archiveRecord.vehicle_ID +
      " has been sucessfully archived";
    var body = {
      vehicle_ID: archiveRecord.vehicle_ID,
      date_inservice: null,
      date_outservice: null,
    };

    if (checkboxState) {
      body = {
        vehicle_ID: archiveRecord.vehicle_ID,
        date_inservice: archiveRecord.date_inservice,
        date_outservice: archiveRecord.date_outservice,
      };
      messageValue =
        "Vehicle with ID: " +
        archiveRecord.vehicle_ID +
        " has been sucessfully archived with technical records starting from " +
        archiveRecord.date_inservice +
        " until " +
        archiveRecord.date_outservice;
    }

    // console.log(body);

    const url =
      process.env.REACT_APP_API_URL + "/ArchivedVehicles/" + "archive/";
    const SRData = await API.getAPIData(url, "POST", body);
    console.log(SRData);
    if (SRData.status === 200) {
      await SRData.json();
      dispatch({
        type: "NOTIFICATION",
        value: true,
        message: messageValue,
        messageType: "success",
      });
    }
    handleClose();
    setRefreshPageSend();
  }

  const handleCheckBoxChange = (event) => {
    setCheckboxState(event.target.checked);
    console.log(event.target.checked);
  };

  useEffect(() => {
    if (open) {
      if (newRecord) {
        getVehicleHistory(vehicleClicked);
        console.log(vehicleClicked);
        // TODO: use sessionStorage or localStorage to store local variables and logic
        // sessionStorage.setItem("vehicle_ID", vehicleClicked);

        setArchiveRecord({
          ...archiveRecord,
          vehicle_ID: vehicleHistory && vehicleHistory.vehicle_ID,
          chassis_no: vehicleHistory && vehicleHistory.chassis_no,
          date_inservice: vehicleHistory && vehicleHistory.date_inservice,
          date_outservice: vehicleHistory && vehicleHistory.date_outservice,
        });
      }
    }
    // return ();
  }, [open, vehicleHistory && vehicleHistory.vehicle_ID]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        TransitionComponent={Transition}
        maxWidth={"md"}
        fullWidth={true}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {"Archive Vehicle"}
            </Typography>
          </Toolbar>
        </AppBar>
        {
          <PageBase
            title={"Archive " + (archiveRecord && archiveRecord.vehicle_ID)}
            // body="Hey how are you doing?"
            // navigation="Application / Form Page"
            // goBack={goBack}
            // deleteRecord={deleteRecord}
            // deleteTooltip={"Delete"}
            // newRecord={newRecord}
            // isDelete
          >
            <form>
              <TextField
                // hintText="Vehicle ID"
                // label="Vehicle ID"
                // sx={{}}
                fullWidth={true}
                helperText="Vehicle ID"
                // variant="filled"
                margin="normal"
                value={archiveRecord && archiveRecord.vehicle_ID}
                disabled={true}
                onChange={(event) =>
                  setArchiveRecord({
                    ...archiveRecord,
                    vehicle_ID: archiveRecord && archiveRecord.vehicle_ID,
                  })
                }
              />
              <TextField
                // hintText="Chassis Number"
                // label="Chassis Number"
                helperText="Chassis Number"
                // variant="filled"
                fullWidth={true}
                margin="normal"
                value={archiveRecord && archiveRecord.chassis_no}
                disabled={true}
                onChange={(event) =>
                  setArchiveRecord({
                    ...archiveRecord,
                    chassis_no: archiveRecord && archiveRecord.chassis_no,
                  })
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkboxState}
                    onChange={handleCheckBoxChange}
                    name="includeAll"
                    color="primary"
                  />
                }
                label="Specify date range of records to be archived"
              />

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-start"
                  label="Start Date"
                  format="d MMM, yyyy"
                  fullWidth={true}
                  disabled={!checkboxState}
                  showTodayButton
                  value={archiveRecord && archiveRecord.date_inservice}
                  onChange={(value) =>
                    setArchiveRecord({
                      ...archiveRecord,
                      date_inservice: value.toISOString().substring(0, 10),
                    })
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputProps={{ readOnly: true }}
                />
              </MuiPickersUtilsProvider>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-end"
                  label="End Date"
                  format="d MMM, yyyy"
                  fullWidth={true}
                  disabled={!checkboxState}
                  showTodayButton
                  value={archiveRecord && archiveRecord.date_outservice}
                  onChange={(value) =>
                    //  console.log(value)
                    setArchiveRecord({
                      ...archiveRecord,
                      date_outservice: value.toISOString().substring(0, 10),
                    })
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputProps={{ readOnly: true }}
                />
              </MuiPickersUtilsProvider>

              <div style={styles.buttons}>
                <Button
                  style={styles.saveButton}
                  variant="contained"
                  color="secondary"
                  onClick={submitArchive}
                >
                  Archive
                </Button>
              </div>
            </form>
            {/* )} */}
          </PageBase>
        }
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isSignedIn: state.isSignedIn,
  accessToken: state.accessToken,
  refreshToken: state.refreshToken,
  vehicleTableState: state.vehicleTableState,
  serviceRecordTableState: state.serviceRecordTableState,
});

export default connect(mapStateToProps)(
  withStyles(stripedTableStyle, {
    withTheme: true,
  })(ArchiveModal)
);
