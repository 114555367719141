import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import PageBase from "../../PageBase";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { grey } from "@material-ui/core/colors";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import API from "../../../services/API";
import { Build } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";

const stripedTableStyle = (theme) => ({
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
});

const VehicleForm = ({ dispatch }) => {
  const history = useHistory();
  const styles = {
    toggleDiv: {
      marginTop: 20,
      marginBottom: 5,
    },
    toggleLabel: {
      color: grey[400],
      fontWeight: 100,
    },
    buttons: {
      marginTop: 30,
      float: "right",
      width: "100%",
    },
    saveButton: {
      marginLeft: 5,
    },
    deleteButton: {
      marginLeft: 5,
      backgroundColor: "#d22929",
    },
  };

  const [vehicleData, setVehicleData] = useState(null);
  const [availableVehicleList, setAvailableVehicleList] = useState(null);

  async function getAvailableVehiclesList() {
    const url = process.env.REACT_APP_API_URL + "/ListVehicleNumbers/available";
    const SRData = await API.getAPIData(url, "GET", null);
    if (SRData.status === 200) {
      let responseObjectTemp = await SRData.json();
      console.log(responseObjectTemp.unshift(""));
      setAvailableVehicleList(responseObjectTemp);
    }
  }

  useEffect(() => {
    console.log("STATE", history.location.state);
    if (history.location.state) {
      setVehicleData(history.location.state.data);
    }

    // If it's a new record, fetch all the available vehicle IDs to populate the drop down
    if (history.location.state.newRecord) {
      getAvailableVehiclesList().then((value) => console.log(value));
    } else {
      setAvailableVehicleList([history.location.state.data.vehicle_ID]);
    }
  }, []);

  useEffect(() => {
    console.log("Updated VehicleData", vehicleData);
  }, [vehicleData]);

  async function saveBtn() {
    console.log("Updated VehicleData now now");
    const response = await API.getAPIData(
      vehicleData.url,
      history.location.state.newRecord ? "POST" : "PUT",
      vehicleData
    );
    console.log(response);
    if (response.status === 201 || response.status === 200) {
      dispatch({ type: "CONFIRMATION", value: true });
      history.goBack();
    }
  }

  // async function deleteRecord() {
  //   const response = await API.getAPIData(
  //     vehicleData.url,
  //     'DELETE',
  //     vehicleData
  //   );
  //   console.log(response);
  //   // Delete was successful
  //   if (response.status === 204) {
  //     history.goBack();
  //   }
  // }

  async function toggleVehicleStatus() {
    console.log("Archiving vehicle: " + vehicleData.vehicle_ID);

    const body = {
      vehicle_ID: vehicleData.vehicle_ID,
    };
    const url = process.env.REACT_APP_API_URL + "/ArchivedVehicles/archive/";
    const SRData = await API.getAPIData(url, "POST", body);
    if (SRData.status === 200) {
      let responseObjectTemp = await SRData.json();
      console.log(responseObjectTemp);
      dispatch({ type: "CONFIRMATION", value: true });
      history.goBack();
    }
  }

  function goBack() {
    history.goBack();
  }

  function viewServiceRecords() {
    console.log("Going to service recorrds", vehicleData);
    const goToSuffix = vehicleData.active_inservice ? "active/" : "archived/";
    history.push("/service/" + goToSuffix, {
      vehicleSelected: vehicleData.vehicle_ID,
    });
  }

  const additionalActions = [
    {
      icon: <Build />,
      actionToolTip: "View Services Records",
      callback: viewServiceRecords,
    },
  ];

  return (
    <PageBase
      title="Add/Edit Vehicle"
      // navigation="Application / Form Page"
      goBack={goBack}
      deleteRecord={toggleVehicleStatus}
      deleteTooltip={"Archive Vehicle"}
      isArchive={true}
      additionalActions={additionalActions}
      newRecord={history.location.state.newRecord}
    >
      {vehicleData && (
        <div>
          <div style={styles.toggleDiv}>
            <FormControlLabel
              control={
                <Switch
                  checked={vehicleData.active_inservice}
                  onChange={(event) =>
                    setVehicleData({
                      ...vehicleData,
                      active_inservice: event.target.checked,
                    })
                  }
                />
              }
              label="Active In-Service"
            />
          </div>

          {availableVehicleList && (
            <Autocomplete
              id="vehicle-id"
              options={availableVehicleList}
              fullWidth={true}
              value={vehicleData.vehicle_ID}
              disabled={!history.location.state.newRecord}
              onChange={(event, newValue) => {
                console.log(newValue);
                if (newValue) {
                  setVehicleData({ ...vehicleData, vehicle_ID: newValue });
                } else {
                  setVehicleData({ ...vehicleData, vehicle_ID: "" });
                }
                console.log(vehicleData);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  label="Vehicle ID"
                  fullWidth={true}
                />
              )}
            />
          )}

          {/*<FormControl fullWidth={true}>*/}
          {/*  <InputLabel htmlFor="Vehicle ID">Vehicle ID</InputLabel>*/}
          {/*  <Select*/}
          {/*    inputProps={{*/}
          {/*      name: "Vehicle ID",*/}
          {/*      id: "Vehicle ID"*/}
          {/*    }}*/}
          {/*    fullWidth={true}*/}
          {/*    margin="normal"*/}
          {/*    disabled={!history.location.state.newRecord}*/}
          {/*    defaultValue={history.location.state.newRecord ? '' : vehicleData.vehicle_ID}*/}
          {/*    onChange={event => setVehicleData({...vehicleData, vehicle_ID: event.target.value})}*/}
          {/*  >*/}
          {/*    {*/}
          {/*      availableVehicleList && availableVehicleList.map(value => <MenuItem id={value} value={value}>{value}</MenuItem>)*/}
          {/*    }*/}
          {/*  </Select>*/}
          {/*</FormControl>*/}

          <TextField
            label="Chassis Number"
            fullWidth={true}
            margin="normal"
            value={vehicleData.chassis_no}
            onChange={(event) =>
              setVehicleData({ ...vehicleData, chassis_no: event.target.value })
            }
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              showTodayButton={true}
              id="date-added"
              label="Date Added"
              format="d MMM, yyyy"
              fullWidth={true}
              value={vehicleData.date_added}
              onChange={(value) =>
                setVehicleData({
                  ...vehicleData,
                  date_added: value.toISOString(),
                })
              }
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{ readOnly: true }}
            />

            <KeyboardDatePicker
              showTodayButton={true}
              margin="normal"
              id="date-in-service"
              // Show TODAY button
              label="Date In-service"
              format="d MMM, yyyy"
              fullWidth={true}
              value={vehicleData.date_inservice}
              onChange={(value) =>
                setVehicleData({
                  ...vehicleData,
                  date_inservice: value.toISOString().substring(0, 10),
                })
              }
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{ readOnly: true }}
            />

            <KeyboardDatePicker
              showTodayButton={true}
              margin="normal"
              id="date-out-service"
              label="Date Out-service"
              format="d MMM, yyyy"
              fullWidth={true}
              value={vehicleData.date_outservice}
              onChange={(value) =>
                setVehicleData({
                  ...vehicleData,
                  date_outservice: value.toISOString().substring(0, 10),
                })
              }
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{ readOnly: true }}
            />

            <TextField
              label="Engine Number"
              fullWidth={true}
              margin="normal"
              value={vehicleData.engine_no}
              onChange={(event) =>
                setVehicleData({
                  ...vehicleData,
                  engine_no: event.target.value,
                })
              }
            />

            <TextField
              label="Model"
              fullWidth={true}
              margin="normal"
              value={vehicleData.model}
              onChange={(event) =>
                setVehicleData({ ...vehicleData, model: event.target.value })
              }
            />

            <TextField
              label="Service Interval"
              type={"number"}
              fullWidth={true}
              margin="normal"
              value={vehicleData.service_interval}
              onChange={(event) =>
                setVehicleData({
                  ...vehicleData,
                  service_interval: parseInt(event.target.value),
                })
              }
            />

            <KeyboardDatePicker
              // showTodayButton={true}
              margin="normal"
              id="year"
              views={["year"]}
              label="Year"
              format="yyyy"
              value={"01/01/" + vehicleData.year_model}
              onChange={(value) =>
                setVehicleData({
                  ...vehicleData,
                  year_model: value.getFullYear(),
                })
              }
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{ readOnly: true }}
            />
          </MuiPickersUtilsProvider>

          <div style={styles.buttons}>
            <Button
              style={styles.saveButton}
              variant="contained"
              // type="submit"
              color="secondary"
              onClick={saveBtn}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </PageBase>
  );
};

const mapStateToProps = (state) => ({
  isSignedIn: state.isSignedIn,
  accessToken: state.accessToken,
  refreshToken: state.refreshToken,
});

export default connect(mapStateToProps)(
  withStyles(stripedTableStyle, {
    withTheme: true,
  })(VehicleForm)
);
