import React from "react";
import Faker from "faker";
import {
  Home,
  DriveEta,
  SettingsInputComponent,
  VpnKey,
  CheckCircle,
  AddCircle,
  Archive,
  LocalShipping,
} from "@material-ui/icons";

import { Badge } from "@material-ui/core";

// Required to open empty vehicle form
const emptyVehicleRecord = {
  active_inservice: false,
  chassis_no: "",
  date_added: new Date().toISOString(),
  date_inservice: new Date().toISOString().substring(0, 10),
  date_outservice: new Date().toISOString().substring(0, 10),
  engine_no: "",
  model: "",
  service_interval: 0,
  url: process.env.REACT_APP_API_URL + "/Vehicles/",
  vehicle_ID: "",
  year_model: 2021,
};

// Required to open empty service record form
const emptyServiceRecord = {
  url: process.env.REACT_APP_API_URL + "/ServiceRecords/",
  vehicle_ID: "",
  chassis_no: "",
  odometer: 0,
  service_jobno: "",
  service_brakes: 0,
  service_type: "",
  tyre_type: "",
  tyre_size: "",
  tyre_pos: "",
  description_tag: "",
  job_data: [
    {
      hotkey: "General",
      description: "",
      remarks: "",
    },
  ],
  date_added: new Date().toISOString(),
  date_updated: new Date().toISOString(),
  date_completed: new Date().toISOString().substring(0, 10),
  remarks: "None",
  record_type: "",
};

const data = {
  menus: [
    { text: "Home", icon: <Home />, link: "/" },
    {
      text: "Vehicles",
      icon: <DriveEta />,
      subMenus: [
        {
          text: "Active",
          icon: <CheckCircle />,
          link: "/vehicles/active",
        },
        {
          text: "Archived",
          icon: <Archive />,
          link: "/vehicles/archived",
        },
        {
          text: "Add Vehicle",
          icon: <AddCircle />,
          link: {
            pathname: "/vehicleForm",
            state: {
              data: emptyVehicleRecord,
              newRecord: true,
            },
          },
        },
      ],
    },
    // { text: "Technical Records", icon: <Build />, link: "/technical" },
    // { text: "Trailers", icon: <LocalShipping />, link: "/trailers" },
    {
      text: "Service Records",
      icon: <SettingsInputComponent />,
      subMenus: [
        {
          text: "Active",
          icon: <CheckCircle />,
          link: "/service/active",
        },
        {
          text: "Archived",
          icon: <Archive />,
          link: "/service/archived",
        },
        {
          text: "Add Service Record",
          icon: <AddCircle />,
          link: {
            pathname: "/serviceRecordForm",
            state: {
              data: emptyServiceRecord,
              newRecord: true,
            },
          },
        },
      ],
    },
    { text: "Hotkeys", icon: <VpnKey />, link: "/hotkeys" },
    {
      text: "Selected Vehicle",
      icon: <LocalShipping />,
      link: "/selectedVehicle",
    },
  ],
};

export default data;
