import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import globalStyles from "../styles";
import { Tooltip } from "@material-ui/core";
import { Archive, Check, Delete } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";

const PageBase = (props) => {
  const {
    title,
    deleteRecord,
    deleteEnabled,
    newRecord,
    deleteTooltip,
    additionalActions,
    isArchive,
    isDelete,
  } = props;
  return (
    <div>
      {/* <span style={globalStyles.navigation}>{navigation}</span> */}

      <Paper style={globalStyles.paper}>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            {/* <IconButton aria-label="delete" onClick={goBack}>
              <ArrowBack/>
            </IconButton> */}
            {/*<Fab*/}
            {/*  color="primary"*/}
            {/*  aria-label="add"*/}
            {/*  size={"small"}*/}
            {/*  // style={{*/}
            {/*  //   position: "fixed",*/}
            {/*  //   bottom: "2rem",*/}
            {/*  //   right: "2rem"*/}
            {/*  // }}*/}
            {/*  onClick={goBack}*/}
            {/*>*/}
            {/*  <ArrowBack/>*/}
            {/*</Fab>*/}
            <div>
              <h3 style={globalStyles.title}>{title}</h3>
            </div>
          </div>

          <div>
            {newRecord === false &&
              additionalActions &&
              additionalActions.map((action, index) => (
                <Tooltip key={index} title={action.actionToolTip}>
                  <IconButton
                    aria-label="delete"
                    style={{ marginBottom: 20 }}
                    onClick={action.callback}
                  >
                    {action.icon}
                  </IconButton>
                </Tooltip>
              ))}

            {newRecord === false && (
              <Tooltip title={deleteTooltip}>
                <IconButton
                  // TODO: Implement same Archive Workflow from modal, disabled button for now.
                  disabled={!deleteEnabled}
                  aria-label="delete"
                  style={{ marginBottom: 20 }}
                  onClick={deleteRecord}
                >
                  {isArchive ? <Archive /> : isDelete ? <Delete /> : <Check />}
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>

        <Divider />
        {props.children}

        <div style={globalStyles.clear} />
      </Paper>
    </div>
  );
};

PageBase.propTypes = {
  title: PropTypes.string,
  navigation: PropTypes.string,
  children: PropTypes.element,
};

export default PageBase;
