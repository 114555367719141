import React from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Header from "../components/Header";
import LeftDrawer from "../components/LeftDrawer";
import Data from "../data";
import NotFound from "./NotFoundPage";
import { ThemeProvider } from "@material-ui/core/styles";
import defaultTheme, { customTheme } from "../theme";
import HomePage from "./HomePage";
import Hotkeys from "../components/VT/Hotkeys";
import { connect } from "react-redux";
import VehicleForm from "../components/VT/Forms/VehicleForm";
import ServiceRecordForm from "../components/VT/Forms/ServiceRecordForm";
import HotKeyForm from "../components/VT/Forms/HotKeyForm";
import LoginMUI from "./LoginMUI";
import PasswordResetRequest, {
  PasswordResetConfirmPage,
} from "./PasswordResetPage";
import ActiveVehicles from "./Vehicles/ActiveVehicles";
import ArchivedVehicles from "./Vehicles/ArchivedVehicles";
import ActiveServiceRecords from "./ServiceRecords/ActiveServiceRecords";
import ArchivedServiceRecords from "./ServiceRecords/ArchivedServiceRecords";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import SelectedVehicle from "./SelectedVehicle/SelectedVehicle";

const styles = (theme) => ({
  container: {
    margin: "0px 20px 20px 15px",
    paddingLeft: defaultTheme.drawer.width,
    [defaultTheme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
    // width: `calc(100% - ${defaultTheme.drawer.width}px)`
  },
  containerFull: {
    paddingLeft: defaultTheme.drawer.miniWidth,
    [defaultTheme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  settingBtn: {
    top: 80,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    color: "white",
    width: 48,
    right: 0,
    height: 48,
    opacity: 0.9,
    padding: 0,
    zIndex: 999,
    position: "fixed",
    minWidth: 48,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  place_keeper: {
    height: "80px",
    [defaultTheme.breakpoints.down("xs")]: {
      height: "60px",
    },
  },
  overrides: {
    MUIDataTableBodyCell: {
      root: {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  },
});

class App extends React.Component {
  constructor(props) {
    super(props);
    // nav bar default open in desktop screen, and default closed in mobile screen
    this.state = {
      theme: defaultTheme,
      anchorEl: null,
      rightDrawerOpen: false,
      navDrawerOpen:
        window && window.innerWidth >= defaultTheme.breakpoints.values.lg,
    };
    this.handleChangeRightDrawer = this.handleChangeRightDrawer.bind(this);
    this.handleChangeNavDrawer = this.handleChangeNavDrawer.bind(this);
    this.handleChangeTheme = this.handleChangeTheme.bind(this);
    this.setAnchorEl = this.setAnchorEl.bind(this);
  }

  handleChangeNavDrawer() {
    this.setState({
      navDrawerOpen: !this.state.navDrawerOpen,
      anchorEl: null,
    });
  }

  setAnchorEl(obj) {
    this.setState({
      anchorEl: obj,
    });
  }

  handleChangeRightDrawer() {
    this.setState({
      rightDrawerOpen: !this.state.rightDrawerOpen,
    });
  }

  handleChangeTheme(colorOption) {
    const theme = customTheme({
      palette: colorOption,
    });
    this.setState({
      theme,
    });
  }

  render() {
    const {
      classes,
      isSignedIn,
      openNotification,
      notificationMessage,
      notificationType,
      dispatch,
    } = this.props;
    // const {navDrawerOpen, rightDrawerOpen, theme, anchorEl} = this.state;
    const { navDrawerOpen, theme, anchorEl } = this.state;

    return (
      <ThemeProvider theme={theme}>
        {isSignedIn ? (
          <>
            <Header
              handleChangeNavDrawer={this.handleChangeNavDrawer}
              navDrawerOpen={navDrawerOpen}
            />
            <Snackbar
              open={openNotification}
              autoHideDuration={4000}
              onClose={() => dispatch({ type: "NOTIFICATION", value: false })}
            >
              <Alert
                onClose={() => dispatch({ type: "NOTIFICATION", value: false })}
                severity={notificationType}
              >
                {notificationMessage}
              </Alert>
            </Snackbar>
            <LeftDrawer
              navDrawerOpen={navDrawerOpen}
              handleChangeNavDrawer={this.handleChangeNavDrawer}
              menus={Data.menus}
              anchorEl={anchorEl}
              setAnchorEl={this.setAnchorEl}
            />
            {/*<ButtonBase*/}
            {/*    color="inherit"*/}
            {/*    classes={{root: classes.settingBtn}}*/}
            {/*    onClick={this.handleChangeRightDrawer}*/}
            {/*>*/}
            {/*    <i className="fa fa-cog fa-3x"/>*/}
            {/*</ButtonBase>*/}
            {/*<RightDrawer*/}
            {/*    rightDrawerOpen={rightDrawerOpen}*/}
            {/*    handleChangeRightDrawer={this.handleChangeRightDrawer}*/}
            {/*    handleChangeTheme={this.handleChangeTheme}*/}
            {/*/>*/}
            <div className={classNames(classes.place_keeper)}></div>
            <div
              className={classNames(
                classes.container,
                !navDrawerOpen && classes.containerFull
              )}
            >
              <Switch>
                <Route exact path="/" component={HomePage} />
                {/* <Route path="/dashboard" component={Dashboard} /> */}
                <Route
                  exact
                  path="/vehicles/active"
                  component={ActiveVehicles}
                />
                <Route
                  exact
                  path="/vehicles/archived"
                  component={ArchivedVehicles}
                />
                {/*<Route path="/technical" component={TechnicalRecords}/>*/}
                {/*<Route path="/trailers" component={Trailers}/>*/}
                <Route
                  path="/service/active"
                  component={ActiveServiceRecords}
                />
                <Route
                  path="/service/archived"
                  component={ArchivedServiceRecords}
                />
                <Route path="/hotkeys" component={Hotkeys} />
                <Route path="/vehicleForm" component={VehicleForm} />
                <Route
                  path="/serviceRecordForm"
                  component={ServiceRecordForm}
                />
                <Route path="/hotKeyForm" component={HotKeyForm} />

                <Route path="/selectedVehicle" component={SelectedVehicle} />
                {/*<Route path="/form" component={Form}/>*/}
                {/*<Route path="/table/basic" component={BasicTable}/>*/}
                {/*<Route path="/table/data" component={DataTable}/>*/}
                <Route component={NotFound} />
              </Switch>
            </div>
          </>
        ) : (
          <>
            <Switch>
              <Route
                exact
                path="/password/reset/request"
                component={PasswordResetRequest}
              />
              <Route
                path="/password/reset/confirm/:id/:token"
                component={PasswordResetConfirmPage}
              />
              <Route component={LoginMUI} />
            </Switch>
          </>
        )}
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  children: PropTypes.element,
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isSignedIn: state.isSignedIn,
  accessToken: state.accessToken,
  refreshToken: state.refreshToken,
  openNotification: state.openNotification,
  notificationMessage: state.notificationMessage,
  notificationType: state.notificationType,
});

export default connect(mapStateToProps)(withStyles(styles)(App));
