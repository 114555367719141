import store from "../redux/store";

// const API = () => {
//     function login(username, password) {
//         console.log('Loggin in', username, password);
//     }
// };
// const mapStateToProps = (state) => ({
//     isSignedIn: state.isSignedIn
// });

class API {
  async loginAPI(username, password) {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/auth/jwt/create/",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      }
    );
    return response;
  }

  async refreshJWT(refreshToken) {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/auth/jwt/refresh/",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refresh: refreshToken,
        }),
      }
    );
    // console.log(response);
    return response;
  }

  // Generic caller to get a list of Vehicles/Trailers/Hotkeys/ServicesRecords/TechnicalRecords
  async getAPIData(url, method, body) {
    try {
      let response = await this.APICall(url, method, body);
      if (response.status === 401) {
        // Unauthorized

        // Get refresh token from store
        const state = store.getState();
        const refreshToken = state.refreshToken;

        // Refresh the token
        let refresh = await this.refreshJWT(refreshToken);

        // Refresh was successful, try the API Call again
        if (refresh.status === 200) {
          const newAccessToken = (await refresh.json()).access;
          // console.log(newAccessToken);
          store.dispatch({ type: "ACCESS", value: newAccessToken });
          return await this.APICall(url, method, body);
        } else {
          // If token can't be refresh, clear refresh and access token, and set login to false
          store.dispatch({ type: "ACCESS", value: null });
          store.dispatch({ type: "REFRESH", value: null });
          store.dispatch({ type: "LOGIN", value: false });
        }
      }
      return response;
    } catch (e) {
      // console.log("An error occurred", e);
      return e;
    }
  }

  // Generic caller to get a list of Vehicles/Trailers/Hotkeys/ServicesRecords/TechnicalRecords
  async getAPIDataXLSX(url, method, body, format, setIsLoading) {
    try {
      let response = await this.APICallXLSX(
        url,
        method,
        body,
        format,
        setIsLoading
      );
      if (response.status === 401) {
        // Unauthorized

        // Get refresh token from store
        const state = store.getState();
        const refreshToken = state.refreshToken;

        // Refresh the token
        let refresh = await this.refreshJWT(refreshToken);

        // Refresh was successful, try the API Call again
        if (refresh.status === 200) {
          const newAccessToken = (await refresh.json()).access;
          // console.log(newAccessToken);
          store.dispatch({ type: "ACCESS", value: newAccessToken });
          return await this.APICallXLSX(
            url,
            method,
            body,
            format,
            setIsLoading
          );
        } else {
          // If token can't be refresh, clear refresh and access token, and set login to false
          store.dispatch({ type: "ACCESS", value: null });
          store.dispatch({ type: "REFRESH", value: null });
          store.dispatch({ type: "LOGIN", value: false });
          setIsLoading(false);
        }
      }
      return response;
    } catch (e) {
      // console.log("An error occurred", e);
      setIsLoading(false);
      return e;
    }
  }

  async APICallXLSX(url, method, body, format, setIsLoading) {
    const state = store.getState();
    // console.log(state);
    const accessToken = state.accessToken;

    let requestParamaters = {
      method: method,
      headers: {
        Accept: "application/" + format,
        "Content-Type": "application/" + format,
        Authorization: "JWT " + accessToken,
      },
      // body: body && JSON.stringify(body)
    };

    fetch(url, requestParamaters)
      .then((response) => response.blob())
      .then((blob) => {
        // console.log(blob);

        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Records.xlsx`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        setIsLoading(false);

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });

    // console.log(requestParamaters);
    return await fetch(url, requestParamaters);
  }

  async APICall(url, method, body) {
    const state = store.getState();
    // console.log(state);
    const accessToken = state.accessToken;

    let requestParamaters = {
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "JWT " + accessToken,
      },
      // body: body && JSON.stringify(body)
    };

    // If it's not a GET, it's a PUT, POST or DELETE and we need to send a body
    if (method !== "GET") {
      requestParamaters["body"] = JSON.stringify(body);
    }

    // console.log(requestParamaters);
    return await fetch(url, requestParamaters);
  }

  async APICallUnauthorized(url, method, body) {
    let requestParamaters = {
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      // body: body && JSON.stringify(body)
    };

    // If it's not a GET, it's a PUT, POST or DELETE and we need to send a body
    if (method !== "GET") {
      requestParamaters["body"] = JSON.stringify(body);
    }

    // console.log(requestParamaters);
    return await fetch(url, requestParamaters);
  }

  // async getVehicles(accessToken) {
  //   const response = await fetch('https://vttapi.intentio.co.za/Vehicles/', {
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',
  //       'Authorization': 'JWT ' + accessToken
  //     }
  //   });
  //   return response;
  // }

  async setVehicle(accessToken, vehicleData) {
    // console.log("Put url", vehicleData.url);
    const response = await fetch(vehicleData.url, {
      method: "PUT",
      body: JSON.stringify(vehicleData),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "JWT " + accessToken,
      },
    });
    return response;
  }

  async deleteVehicle(accessToken, vehicleData) {
    // console.log("Put url", vehicleData.url);
    const response = await fetch(vehicleData.url, {
      method: "DELETE",
      body: JSON.stringify(vehicleData),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "JWT " + accessToken,
      },
    });
    return response;
  }

  async addVehicle(accessToken, vehicleData) {
    // console.log("Put url", vehicleData.url);
    const response = await fetch(vehicleData.url, {
      method: "POST",
      body: JSON.stringify(vehicleData),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "JWT " + accessToken,
      },
    });
    return response;
  }

  async setServiceRecord(accessToken, serviceRecordData) {
    // console.log("Put url", serviceRecordData.url);
    let url = serviceRecordData.url;
    // delete serviceRecordData['url'];
    // console.log(serviceRecordData);
    const response = await fetch(url, {
      method: "PUT",
      body: JSON.stringify(serviceRecordData),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "JWT " + accessToken,
      },
    });
    return response;
  }

  async addServiceRecord(accessToken, serviceRecordData) {
    // console.log("Put url", serviceRecordData.url);
    let url = serviceRecordData.url;
    // delete serviceRecordData['url'];
    // console.log(serviceRecordData);
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(serviceRecordData),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "JWT " + accessToken,
      },
    });
    return response;
  }

  async deleteServiceRecord(accessToken, serviceRecordData) {
    // console.log("Put url", serviceRecordData.url);
    let url = serviceRecordData.url;
    // delete serviceRecordData['url'];
    // console.log(serviceRecordData);
    const response = await fetch(url, {
      method: "DELETE",
      body: JSON.stringify(serviceRecordData),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "JWT " + accessToken,
      },
    });
    return response;
  }

  async setHotKey(accessToken, hotKey) {
    // console.log("Put url", hotKey.url);
    const response = await fetch(hotKey.url, {
      method: "PUT",
      body: JSON.stringify(hotKey),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "JWT " + accessToken,
      },
    });
    return response;
  }

  async addHotKey(accessToken, hotKey) {
    // console.log("Put url", hotKey.url);
    const response = await fetch(hotKey.url, {
      method: "POST",
      body: JSON.stringify(hotKey),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "JWT " + accessToken,
      },
    });
    return response;
  }

  async deleteHotKey(accessToken, hotKey) {
    // console.log("Put url", hotKey.url);
    const response = await fetch(hotKey.url, {
      method: "DELETE",
      body: JSON.stringify(hotKey),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "JWT " + accessToken,
      },
    });
    return response;
  }
}

const APIService = new API();
export default APIService;
